import React from 'react';
import { Link } from 'react-router-dom';

// Images
// import IMGCompanyLogo from '../../images/logo.png'
import IMGCompanyLogo from '../../images/neuraltraders-logo-text.png'
import { LazyLoadImage } from "react-lazy-load-image-component";

function Bottom() {

    return (
        <>
            <div className="bottom section-padding">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6"> */}
                        <div className="col-12 col-md-5">
                            <div className="bottom-logo">
                                <LazyLoadImage className="pb-3" src={IMGCompanyLogo} alt="NeuralTraders Logo" style={{"maxWidth": "300px"}}/>
                                <p className='py-2'>Elevate your cryptocurrency trading with NeuralTraders—where advanced algorithms meet user-friendly design, automating success and simplifying profits.</p>
                                {/* <p className='py-2'>NeuralTraders, where advanced algorithms meet user-friendly design.</p> */}
                            </div>
                        </div>
                        <div className='col-12 col-md-1' />
                        <div className="col-12 col-md-6">
                            <div className="row">
                                <div className="col-6">
                                    <div className="bottom-widget">
                                        <h4 className="widget-title">Company</h4>
                                        <ul>
                                            <li><Link to={"/about-us"}>About Us</Link></li>
                                            <li><Link to={"/pricing"}>Pricing</Link></li>
                                            <li><Link to={"/trading-bots"}>Trading Bots</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="bottom-widget">
                                        <h4 className="widget-title">Policies</h4>
                                        <ul>
                                            <li><Link to={"/terms-of-use"}>Terms of Use</Link></li>
                                            <li><Link to={"/refund-policy"}>Refund Policy</Link></li>
                                            <li><Link to={"/privacy-policy"}>Privacy Policy</Link></li>
                                            <li><Link to={"/cookies-policy"}>Cookies Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Bottom;