import React, { useEffect, useState, useRef } from "react";
import DailyProfit from "../charts/daily-profit";
import CumulativeProfit from "../charts/cumulative-profit";
import PerformancePieChart from "../charts/perfomance";
import OpenTradesTable from "../element/open-trades-table";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import { useAuth } from "../auth/auth-context";
import axios from "axios";
import ClosedTradesTable from "../element/closed-trades-table";
import { Link } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { setKeyOfUserDocument } from "../auth/firebase";
import BottomNavbar from "../layout/bottom-navbar";


function Dashboard() {

    const { currentUser, currentUserData, totalUserBalance, fetchUserData } = useAuth()
    const [botPerfomance, setBotPerfomance] = useState({})
    const [userMembershipStatus, setUserMembershipStatus] = useState("")
    const [botStateChanging, setBotStateChanging] = useState(false)
    const [btnText, setBtnText] = useState('...')
    const [botStatusText, setBotStatusText] = useState("Loading...")
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    const updateData = async () => {
        try {
            await axios.get("/api/postgrep", { params: { uid: currentUser.uid } })
                .then(res => {
                    return setBotPerfomance(res.data)
                })
                .catch((e) => { console.log(e); setBotPerfomance({}) })
        } catch {
            return setBotPerfomance({})
        }
    }

    const renderTooltip = () => {
        if (userMembershipStatus === 'demo') {
            return (
                <>
                    <Link to='/pricing'>
                        <div className="btn btn-outline-warning w-100 mb-4 py-3 fs-5">
                            You are using the Demo version of NeuralTraders. You can only view recent bot trades; no interaction or parameter changes allowed.
                        </div>
                    </Link>
                </>
            )
        } else if (userMembershipStatus.includes("pro") || userMembershipStatus.includes("basic")) {
            if (userMembershipStatus.includes("setup")) {
                return (
                    <>
                        <Link to="/dashboard/connect-to-exchange">
                            <div className="btn btn-primary w-100 mb-4 py-3 fs-5">
                                Please connect our Bot to your exchange account to proceed.
                            </div>
                        </Link>
                    </>
                )
            } else if (userMembershipStatus.includes("ready")) { return <></>}
        } else return <></>
    }

    const renderBotStatus = () => {
        if (botStatusText === "Loading...") return <strong className="text-white">{botStatusText}</strong>
        else if (botStateChanging) return <strong className="text-white">Loading...</strong>
        else if (botStatusText === "Active") return <strong className="text-success">{botStatusText}</strong>
        else if (botStatusText === "Ready") return <strong className="text-primary">{botStatusText}</strong>
        else if (botStatusText === "Stopped") return <strong className="text-warning">{botStatusText}</strong>
        else if (botStatusText === "Error") return <strong className="text-danger">{botStatusText}</strong>
    }

    const onBotInteractionButton = async () => {
        const runStartBotSequence = async () => {
            setBotStateChanging(() => true)
            // If cannot read totalUserBalance -> return error
            if (JSON.stringify(totalUserBalance) === '{}' || !totalUserBalance.hasOwnProperty('value')) {
                NotificationManager.error('Could not read account balance', 'Error:', 2000);
                setBotStatusText("Error");
                setBtnText("Start");
                return setBotStateChanging(false)
            }

            // Attempt to set bot status to 'starting' and call the server to handler bot startup
            if (currentUser) {
                // Calculate appropriate max_open_trades for the bot
                const balance = totalUserBalance.value;
                let max_open_trades;
                if (balance <= 10) {
                    max_open_trades = 1;
                } else if (balance > 10 && balance < 350) {
                    max_open_trades = Math.floor( balance / 6 )
                } else {
                    max_open_trades = 77
                }

                NotificationManager.info('Bot is starting, please wait up to 60 seconds.', 'Information:', 2000);
                await setKeyOfUserDocument(currentUser.uid, {'botStatus': `starting_${max_open_trades}`})
                let botStarted = false;
                await axios.get("/api/check-bot-status", { params: { uid: currentUser.uid, desiredBotStatus: 'started' } })
                    .then(res => {if (res.data.isChangeDetected) {botStarted = true;} else {NotificationManager.error('Error #250 - Please contact Customer Service', 'Error:', 5000)}})
                    .catch(() => NotificationManager.error('Error #248 - Please contact Customer Service', 'Error:', 2000))
                setBotStateChanging(false)
                if (botStarted) {
                    setBotStatusText("Active")
                    setBtnText("Stop")
                } else {
                    setBotStatusText("Error");
                    setBtnText("Start");
                    await setKeyOfUserDocument(currentUser.uid, {'botStatus': "error"})
                }
            } else {
                NotificationManager.error('Error occured - Please contact Customer Service', 'Error:', 2000);
                setBotStatusText("Error");
                setBtnText("Start");
                return setBotStateChanging(false)
            }
        }

        const runStopBotSequence = async () => {
            setBotStateChanging(() => true)
            // If cannot read totalUserBalance -> return error
            if (JSON.stringify(totalUserBalance) === '{}' || !totalUserBalance.hasOwnProperty('value')) {
                NotificationManager.error('Could not read account balance', 'Error:', 2000);
                setBotStatusText("Error");
                setBtnText("Start");
                return setBotStateChanging(false)
            }

            // Attempt to set bot status to 'stopping' and call the server to handler bot stop
            if (currentUser) {
                NotificationManager.info('Bot is stopping, please wait up to 60 seconds.', 'Information:', 2000);
                await setKeyOfUserDocument(currentUser.uid, {'botStatus': "stopping"})
                let botStopped = false;
                await axios.get("/api/check-bot-status", { params: { uid: currentUser.uid, desiredBotStatus: 'stopped' } })
                .then(res => {if (res.data.isChangeDetected) {botStopped = true;} else {NotificationManager.error('Error #250 - Please contact Customer Service', 'Error:', 5000)}})
                    .catch(() => NotificationManager.error('Error #249 - Please contact Customer Service', 'Error:', 2000))
                setBotStateChanging(false)
                if (botStopped) {
                    setBotStatusText("Ready")
                    setBtnText("Start")
                } else {
                    setBotStatusText("Error");
                    setBtnText("Start");
                    await setKeyOfUserDocument(currentUser.uid, {'botStatus': "error"})
                }
            } else {
                NotificationManager.error('Error occured - Please contact Customer Service', 'Error:', 2000);
                setBotStatusText("Error");
                setBtnText("Start");
                return setBotStateChanging(false)
            }
        }

        // start the bot
        if (btnText === 'Start') {
            await runStartBotSequence()
        // stop the bot
        } else if (btnText === 'Stop') {
            await runStopBotSequence()
        // we should never be here but in case we are -> restart the bot
        } else {
            await runStartBotSequence()
        }
    }

    const renderBotInteractionButton = () => {
        // if userMembershipStatus isn't loaded -> just present '...' button
        if (userMembershipStatus === '' || JSON.stringify(currentUserData) === '{}') {
            return <button className="btn btn-outline-white py-0" style={{ "marginLeft": "20px" }}>...</button>
        // if userMembershipStatus is demo or needs API setup -> present noninteractive 'Stop' button
        } else if (userMembershipStatus === 'demo' || userMembershipStatus.includes("setup")){
            return (
                <button className="btn btn-outline-warning py-0" style={{ "marginLeft": "20px" }}>
                    {btnText}
                </button>
            )
        // if userMembershipStatus is pro or basic and API is set up -> present the right button text
        } else if (userMembershipStatus.includes("ready")) {
            if (btnText === 'Start') {
                return (
                    <button className="btn btn-success py-0" style={{ "marginLeft": "20px" }} disabled={botStateChanging} onClick={() => onBotInteractionButton()}>
                        {btnText}
                    </button>
                )
            } else if (btnText === 'Stop') {
                return (
                    <button className="btn btn-outline-warning py-0" style={{ "marginLeft": "20px" }} disabled={botStateChanging} onClick={() => onBotInteractionButton()}>
                        {btnText}
                    </button>
                )
            } else {
                return (
                    <button className="btn btn-outline-light py-0" style={{ "marginLeft": "20px" }} disabled={botStateChanging}>
                        {btnText}
                    </button>
                )
            }
        }
    }

    useEffect(() => {
        const updateUserMembershipStatus = async () => {
            let cud;
            if (JSON.stringify(currentUserData) === '{}') cud = await fetchUserData();
            else cud = currentUserData;

            // if failed to load CUD then reload the page
            if (JSON.stringify(cud) === '{}' || typeof cud === 'undefined') {window.location.reload(false);}

            // if demo membership -> setup noninteractive button
            if (cud.membership.planType === 'demo') {setUserMembershipStatus("demo"); setBotStatusText("Active"); return setBtnText("Stop")}
            
            const apiIsSettedUp = cud.exchangeAPIKey !== '' && cud.exchangeAPISecret !== '';            
            // if API isn't set up, set button to 'Stop' and return 'setup' userMembershipStatus
            if (!apiIsSettedUp) {
                setBtnText("Stop");
                setBotStatusText("Active");
                if (cud.membership.planType.startsWith('pro')) return setUserMembershipStatus("pro_setup");
                else if (cud.membership.planType.startsWith('basic')) return setUserMembershipStatus("basic_setup");
            } else { // Otherwise set 'ready' to userMembershipStatus and find out the right text for btnText
                if (cud.membership.planType.startsWith('pro')) setUserMembershipStatus("pro_ready");
                else if (cud.membership.planType.startsWith('basic')) setUserMembershipStatus("basic_ready");

                // find out the right text for btnText
                if (cud.botStatus === 'started') {setBotStatusText("Active"); return setBtnText("Stop")}
                else {setBotStatusText("Ready");  return setBtnText("Start")}
            }
        };
        updateUserMembershipStatus()

        updateData();
        const interval = setInterval(() => {
            updateData();
        }, 15000);

        const onResize = () => {
            setWindowSize(window.innerWidth)
        }

        window.addEventListener("resize", onResize);
        return () => { clearInterval(interval), window.removeEventListener("resize", onResize) };
    }, [])

    return (
        <>
            <Header2 />
            {windowSize >= 576 && <Sidebar />  }
            {/* <Sidebar /> */}

            <div className="content-body" id="dashboard" style={{"marginLeft": window.innerWidth >= 576 ? "65px" : "6px"}}>
                <div className="container-fluid">
                    <div className="row mb-4">
                        <div className="col-12">
                            {renderTooltip()}
                        </div>
                        <div className="col-12 d-sm-none">
                            <table className="table table-dark table-bordered my-2">
                                <thead>
                                    <tr className="mt-4">
                                        <th className="text-center">Bot Status</th>
                                        <th className="text-center">Trades</th>
                                        <th className="text-center">Wins / Losses</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">
                                            {renderBotStatus()}{" "}
                                            {renderBotInteractionButton()}
                                        </td>
                                        <td className="text-center">{JSON.stringify(botPerfomance) !== "{}" ? botPerfomance.closed_trades.length : '-'}</td>
                                        <td className="text-center">
                                            <span>
                                                <span className="text-success">{JSON.stringify(botPerfomance) !== "{}" ? botPerfomance.wins : '-'}</span>{"   "}
                                                <span className="text-white">/</span>{"   "}
                                                <span className="text-warning">{JSON.stringify(botPerfomance) !== "{}" ? botPerfomance.losses : '-'}</span>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table table-dark table-bordered">
                                <thead>
                                    <tr className="mt-4">
                                        <th className="text-center">Open Profit</th>
                                        <th className="text-center">Closed Profit</th>
                                        <th className="text-center">Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {JSON.stringify(botPerfomance) !== "{}" ?
                                            <td className="text-center"><strong className={botPerfomance.open_profit_abs >= 0 ? "text-success" : "text-warning"}>
                                                {botPerfomance.open_profit_abs >= 0 ? '+' : '-'}{botPerfomance.open_profit.toFixed(2)}% ({botPerfomance.open_profit_abs.toFixed(2)}$)</strong></td>
                                            : <td className="text-center"><strong className="text-white">-- (--)</strong></td>}
                                        {JSON.stringify(botPerfomance) !== "{}" ?
                                            <td className="text-center"><strong className={botPerfomance.closed_profit_abs > 0 ? "text-success" : "text-warning"}>
                                                {botPerfomance.closed_profit_abs > 0 ? '+' : '-'}{botPerfomance.closed_profit.toFixed(2)}% ({botPerfomance.closed_profit_abs.toFixed(2)}$)</strong></td>
                                            : <td className="text-center"><strong className="text-white">-- (--)</strong></td>}
                                        <td className="text-center"><strong className="text-white">{JSON.stringify(totalUserBalance) !== '{}' ? totalUserBalance.value.toFixed(2) + ' USDT' : '0 USDT'}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-12 d-none d-sm-block">
                            <table className="table table-dark table-bordered">
                                <thead>
                                    <tr className="mt-4">
                                        <th className="text-center">Bot Status</th>
                                        <th className="text-center">Trades</th>
                                        <th className="text-center">Open Profit</th>
                                        <th className="text-center">Closed Profit</th>
                                        <th className="text-center">Balance</th>
                                        <th className="text-center">Wins / Losses</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">
                                            {renderBotStatus()}{" "}
                                            {renderBotInteractionButton()}
                                        </td>
                                        <td className="text-center">{JSON.stringify(botPerfomance) !== "{}" ? botPerfomance.closed_trades.length : '-'}</td>
                                        {JSON.stringify(botPerfomance) !== "{}" ?
                                            <td className="text-center"><strong className={botPerfomance.open_profit_abs >= 0 ? "text-success" : "text-warning"}>
                                                {botPerfomance.open_profit_abs >= 0 ? '+' : '-'}{botPerfomance.open_profit.toFixed(2)}% ({botPerfomance.open_profit_abs.toFixed(2)}$)</strong></td>
                                            : <td className="text-center"><strong className="text-white">-- (--)</strong></td>}
                                        {JSON.stringify(botPerfomance) !== "{}" ?
                                            <td className="text-center"><strong className={botPerfomance.closed_profit_abs > 0 ? "text-success" : "text-warning"}>
                                                {botPerfomance.closed_profit_abs > 0 ? '+' : '-'}{botPerfomance.closed_profit.toFixed(2)}% ({botPerfomance.closed_profit_abs.toFixed(2)}$)</strong></td>
                                            : <td className="text-center"><strong className="text-white">-- (--)</strong></td>}
                                        <td className="text-center"><strong className="text-white">{JSON.stringify(totalUserBalance) !== '{}' ? totalUserBalance.value.toFixed(2) + ' USDT' : '0 USDT'}</strong></td>
                                        <td className="text-center">
                                            <span>
                                                <span className="text-success">{JSON.stringify(botPerfomance) !== "{}" ? botPerfomance.wins : '-'}</span>{"   "}
                                                <span className="text-white">/</span>{"   "}
                                                <span className="text-warning">{JSON.stringify(botPerfomance) !== "{}" ? botPerfomance.losses : '-'}</span>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-lg-4">
                            <div className="card text-center h-100">
                                <h6 className="text-white pt-3">Daily Trades</h6>
                                <div className="card-header pb-0 m-0"></div>
                                <div className="card-body">
                                    <div className="row h-100">
                                        <div className="w-100">
                                            <DailyProfit botPerformance={botPerfomance} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card text-center h-100">
                                <h6 className="text-white pt-3">Cumulative Profit</h6>
                                <div className="card-header pb-0 m-0"></div>
                                <div className="card-body">
                                    <div className="row h-100">
                                        {/* <AreaChart /> */}
                                        <div className="w-100">
                                            <CumulativeProfit botPerformance={botPerfomance} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card text-center h-100">
                                <h6 className="text-white pt-3">Performance</h6>
                                <div className="card-header pb-0 m-0"></div>
                                <div className="card-body">
                                    <div className="row h-100">
                                        <div className="w-100">
                                            <PerformancePieChart botPerformance={botPerfomance} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <OpenTradesTable table_data={JSON.stringify(botPerfomance) !== "{}" ? botPerfomance.open_trades : []} />
                        <ClosedTradesTable table_data={JSON.stringify(botPerfomance) !== "{}" ? botPerfomance.closed_trades : []} />
                    </div>
                </div>
            </div>

            {windowSize < 576 && <BottomNavbar />  }
            <Footer2 />
        </>
    );
}

export default Dashboard;
