import React, { useRef, useState, useEffect, useCallback } from "react"
import { Form, Button, Card, Alert, Container, Row, Col } from "react-bootstrap"
import { useAuth } from "../auth/auth-context"
import { Link, useNavigate } from "react-router-dom"
import { asyncTimeout } from "../../helper_functions"
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import particlesConfig from '../element/ParticlesConfig';

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login, currentUser } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard/home')
    }
  }, [currentUser])

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      await asyncTimeout(500);
      // navigate('/dashboard/home');
    } catch (e) {
      const { code, details } = JSON.parse(JSON.stringify(e))
      if (code === "auth/invalid-login-credentials") {
        setError("Wrong credetials. Failed to Log In.")
      } else {
        setError("Failed to log in")
      }
      // console.log(code)
      // console.log(details)
      // setError("Failed to log in")
    }

    setLoading(false)
  }

  const particlesInit = useCallback(async engine => {
    await loadSlim(engine);
  }, []); 

  return (
    <>
      <Particles id="particles" init={particlesInit} options={particlesConfig} />
      <Container>
        <div className="row h-100 mt-5">
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Log In</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" ref={emailRef} required />
                </Form.Group>
                <Form.Group id="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" ref={passwordRef} required />
                </Form.Group>
                <br />
                <Row>
                  <Button size="lg" className="w-50 mx-auto" disabled={loading} type="submit">
                    Log In
                  </Button>
                </Row>
              </Form>
              <div className="w-100 text-center mt-3">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            </Card.Body>
          </Card>
          <div className="w-100 text-center mt-2">
            Need an account? <Link to="/signup">Sign Up</Link>
          </div>
        </div>
      </Container>
    </>
  )
}