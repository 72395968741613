import React from "react"
import { useLayoutEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useLocation } from "react-router-dom"

// Pages
import CustomNavbar from "./jsx/layout/custom-navbar"
import Homepage from './jsx/pages/homepage'
import Signup from "./jsx/pages/signup"
import Login from "./jsx/pages/login"
import ForgotPassword from "./jsx/pages/forgot-password"
import PrivateRoute from "./jsx/auth/private-route"
import Error404 from "./jsx/pages/404"
import Dashboard from "./jsx/pages/dashboard"
import AccountOverview from "./jsx/pages/account-overview"
import ConnectToExchange from "./jsx/pages/connect-to-exchange"
import TradingBots from "./jsx/pages/trading-bots"
import PricingPage from "./jsx/pages/pricing"
import AbousUs from "./jsx/pages/about-us"
import TermsOfUse from "./jsx/pages/terms-of-use"
import RefundPolicy from "./jsx/pages/refund-policy"
import PrivacyPolicy from "./jsx/pages/privacy-policy"
import CookiesPolicy from "./jsx/pages/cookies-policy"
import PurchaseSuccess from "./jsx/pages/purchase-success"
import PurchaseCancelled from "./jsx/pages/purchase-cancelled"
import SetupGuide from "./jsx/pages/setup-guide"
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

// AuthProvider
import { AuthProvider } from "./jsx/auth/auth-context"

// Import styles
import './css/style.css';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://724f34f743eca7b97ccb998400da9a38@o4506286490845184.ingest.sentry.io/4506286492614656",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/neuraltraders\.com\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({top: 0, left: 0, behavior: 'instant'});
  }, [location.pathname]);
  return children
}


function App() {

  return (
    <>
      <Router>
        <AuthProvider>
          <div id="main-wrapper">
            <CustomNavbar />
            <NotificationContainer />
            <Wrapper>
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/trading-bots" element={<TradingBots />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/about-us" element={<AbousUs />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/refund-policy" element={<RefundPolicy />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/cookies-policy" element={<CookiesPolicy />} />
                <Route path="/setup-guide" element={<SetupGuide />} />
                <Route path="*" element={<Error404 />} />
                <Route element={<PrivateRoute />}>
                  <Route path="/purchase-success" element={<PurchaseSuccess />} />
                  <Route path="/purchase-cancelled" element={<PurchaseCancelled />} />
                  <Route path="/dashboard/home" element={<Dashboard />} />
                  <Route path="/dashboard/account-overview" element={<AccountOverview />} />
                  <Route path="/dashboard/connect-to-exchange" element={<ConnectToExchange />} />
                </Route>
              </Routes>
            </Wrapper>
          </div>
        </AuthProvider >
      </Router >
    </>
  )
}

export default App