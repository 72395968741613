import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import Nav from 'react-bootstrap/Nav';
import { useAuth } from "../auth/auth-context"
import { db } from '../auth/firebase';
import { doc, getDoc } from 'firebase/firestore';


export default function ProfileSnippet() {
    const { currentUser, logout } = useAuth()
    const [usernameStr, setUsernameStr] = useState("Dashboard")
    const navigate = useNavigate()

    // useEffect(() => {
    //     if (currentUser.email) {
    //         const fetchData = async () => {
    //             return await getDoc(doc(db, "users", currentUser.uid));
    //         }
    //         fetchData().then(val => setUsernameStr(`${val.data().firstName} ${val.data().lastName}`)).catch(() => setUsernameStr('Dashboard'))
    //     }
    // }, [currentUser])

    async function profileExitClickHandler() {
        try {
            await logout()
            navigate('/');
        } catch {
            alert("Failed to log out")
        }
    }

    return (
        <div className="d-flex align-items-center">
            <Nav.Link as={Link} to="/dashboard/home" className='login-btn'>
                <div className="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#5d78ff" className="bi bi-person" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                    </svg>
                    <strong className='px-1 text-nowrap'>{usernameStr}</strong>
                </div>
            </Nav.Link>
            <Nav.Link as={Link} to="/" className='login-btn'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5d78ff" className="bi bi-x-circle" viewBox="0 0 16 16" onClick={profileExitClickHandler}>
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </Nav.Link>
        </div >
    )
}
