import React, { useCallback } from 'react'
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import particlesConfig from '../element/ParticlesConfig';
import Bottom from '../element/bottom';
import Footer1 from '../layout/footer1';

export default function RefundPolicy() {

    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    return (
        <>
            <Particles id="particles" init={particlesInit} options={particlesConfig} />
            <div className="intro" style={{"minHeight": "450px"}}>
                <div className="container fs-5">
                    <h1>Refund Policy</h1>
                    <p>Thank you for choosing NeuralTraders for your cryptocurrency trading needs. Please read our refund policy carefully before making a purchase.</p>

                    <h2>1. Refund Policy Overview</h2>
                    <p>At NeuralTraders, we take pride in the quality and performance of our cryptocurrency algorithmic trading bots. As our products are digital and involve online services, we do not offer refunds after a purchase has been made.</p>
                    
                    <h2>2. Understanding Our Products</h2>
                    <p>Before making a purchase, we encourage all users to thoroughly review the features, functionalities, and specifications of our trading bots. Additionally, we provide comprehensive documentation and support resources to assist you in making informed decisions about our products.</p>

                    <h2>3. Trial Periods and Demos</h2>
                    <p>To ensure customer satisfaction, we may offer trial periods or demos for certain products. During these trial periods, users have the opportunity to test the functionality and performance of our trading bots before making a final purchase decision. Please take advantage of these opportunities to assess whether our products meet your specific requirements.</p>

                    <h2>4. Technical Support</h2>
                    <p>We are committed to providing excellent customer support to address any issues or concerns you may encounter. If you experience difficulties with our products, please reach out to our support team at customers@neuraltraders.com, and we will be happy to assist you.</p>

                    <h2>5. Subscription Cancellations</h2>
                    <p>For subscription-based services, users have the flexibility to cancel their subscription at any time. Cancellations prevent future billing cycles, but no refunds will be issued for the current subscription period.</p>

                    <h2>6. Changes to Refund Policy</h2>
                    <p>NeuralTraders reserves the right to modify or update this refund policy at any time without prior notice. Changes will be effective immediately upon posting on our website. It is the responsibility of the user to review the refund policy periodically.</p>

                    <h2>Contact Us</h2>
                    <p>If you have any questions or concerns about our refund policy, please contact us at customers@neuraltraders.com. We are here to assist you and ensure a positive experience with NeuralTraders.</p>

                    <br/>
                    <p>Thank you for choosing NeuralTraders for your cryptocurrency trading journey.</p>
                </div>
            </div>
            <Bottom />
            <Footer1 />
        </>)
}