import React from "react";
import Chart from "react-apexcharts";

function PerformancePieChart({ botPerformance }) {

  const computePerformancePieChart = () => {
    let coinPerformance = {}
    for (let ct of botPerformance.closed_trades) {
      let coin_name = ct.base_currency;
      if (!(coin_name in coinPerformance)) {
        coinPerformance[coin_name] = 0
      }
      coinPerformance[coin_name] += ct.close_profit_abs
    }
    // console.log(coinPerformance)
    // Sort the coins alphabetically
    const sortedCoins = Object.keys(coinPerformance).sort();
    const sortedCoinPerformance = {};
    sortedCoins.forEach(key => {
      // sortedCoinPerformance[key] = coinPerformance[key];
      sortedCoinPerformance[key] = Number(coinPerformance[key].toFixed(2));
    });
    // console.log(sortedCoinPerformance)
    return {
      series: { name: 'Closed Trades', data: Object.values(sortedCoinPerformance) },
      labels: Object.keys(sortedCoinPerformance)
    };
  }

  const getPerformancePieChart = () => {
    if (JSON.stringify(botPerformance) === '{}') {
      return { series: { name: 'Closed Trades', data: [] }, labels: [] };
    } else {
      return computePerformancePieChart()
    }
  }
  const seriesData = getPerformancePieChart()

  const chart_options = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      labels: seriesData.labels,
      colors: ['#394ebd', '#3245ad', '#2b3d9e', '#24358e', '#1d2d7f', '#1c2b7d', '#1f3088', '#233592', '#263a9d', '#2a3fa8'],
      stroke: {
        show: false,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: '66%',
            labels: {
              show: true,
              name: {
                color: '#ffffff',
                fontSize: '28px',
              },
              value: {
                color: '#dddddd',
                fontSize: '28px',
                formatter: function (value) {
                  return value + "$";
                }
              },
              total: {
                show: true,
                showAlways: false,
                label: 'Total',
                fontSize: '28px',
                color: '#ffffff',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0).toFixed(2) + "$"
                }
              }
            },
          },
          expandOnClick: false
        }
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: '16px',
        },
        y: {
          formatter: (val) => val + "$",
          title: {
              formatter: (seriesName) => seriesName + ":",
          },
        },
      },
      noData: {
        text: "Data loading...",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "white",
          fontSize: '14px',
          fontFamily: undefined
        }
      }
    },
    series: seriesData.series.data
  }



  return (
    <>
      <Chart
        options={chart_options.options}
        series={chart_options.series}
        type="donut"
      />
    </>
  );
}

export default PerformancePieChart;