import React, { useCallback, useEffect, useState } from 'react'
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import particlesConfig from '../element/ParticlesConfig';
import Bottom from '../element/bottom';
import Footer1 from '../layout/footer1';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth/auth-context';
import axios from 'axios';

export default function PurchaseSuccess() {

    const [paymentStatus, setPaymentStatus] = useState("")
    const [error, setError] = useState("")
    const [timer, setTimer] = useState(0)
    const { fetchUserData } = useAuth()

    useEffect(() => {
        const process_payment = async () => {
            const userData = await fetchUserData();
            
            // If plan already exists, check its conditions
            if (userData.membership.hasOwnProperty('planEndDate')) {
                const planEndDate = new Date(userData.membership.planEndDate);
                const currentDate = new Date();
                if (planEndDate.getTime() > currentDate.getTime()) {
                    return setPaymentStatus("Payment completed!")
                  } else {
                    return setError("Payment needs to be renewed!")
                  }
            }            
            
            // If sessionId is undefined or null -> cannot proceed
            if (userData.membership.sessionId === undefined || userData.membership.sessionId === null) return setError("No payment session. Cannot proceed.") 
            await axios.get("/api/complete-purchase", { params: { sessionId: userData.membership.sessionId, uid: userData.uid } })
                .then((res) => {
                    if (res.statusText === 'OK') return setPaymentStatus('Payment completed!');
                    return setError("Payment wasn't successful!");
                })
                .catch((e) => setError("Payment processing wasn't succesful. Please contact Customer Service."))
        }
        process_payment()

        // set interval incrementor for timer
        const interval = setInterval(() => {
            setTimer(prev => prev + 1);
          }, 1000);
        return () => { clearInterval(interval) };
    }, [])

    const renderPaymentStatus = () => {
        if (error === '') {
            if (paymentStatus === 'Payment completed!') {
                return (<>
                    <h2 className='my-2 text-success'>{paymentStatus}</h2>
                    <Link to="/dashboard/home" className="btn btn-primary my-4 mx-4 fs-4 px-3 py-2"><strong className='text-white'>Go to Dashboard</strong></Link>
                </>)
            } else {
                return (<>
                    <h2 className='my-2 text-info'>{paymentStatus}</h2>
                    <Link className="btn btn-primary my-4 mx-4 fs-4 px-3 py-2"><strong className='text-white'>Time elapsed: {timer} seconds</strong></Link>
                </>)
            }
        } else {
            return <h2 className='my-2 text-danger'>{error}</h2>
        }
    }

    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    return (
        <>
            <Particles id="particles" init={particlesInit} options={particlesConfig} />
            <div className="intro my-4" style={{ "height": "600px" }}>
                <div className="container text-center">
                    <div className="row justify-content-center">
                        <div className="col-6">
                            <h1 className='my-4'>Purchase was successful.</h1>
                            {renderPaymentStatus()}
                        </div>
                    </div>
                </div>
            </div>
            <Bottom />
            <Footer1 />
        </>)
}