const particlesConfig = {
    "fpsLimit": 120,
    "interactivity": {
      "events": {
        "onClick": {
          "enable": false,
          "mode": "push"
        },
        "onHover": {
          "enable": true,
          "mode": "repulse"
        },
        "resize": true
      },
      "modes": {
        "push": {
          "quantity": 4
        },
        "repulse": {
          "distance": 100,
          "duration": 0.5
        }
      }
    },
    "particles": {
      "color": {
        "value": "#ffffff"
      },
      "links": {
        "color": "#c7c7c7",
        "distance": 300,
        "enable": true,
        "opacity": 0.2,
        "width": 1
      },
      "move": {
        "direction": "none",
        "enable": true,
        "outModes": {
          "default": "bounce"
        },
        "random": false,
        "speed": 0.5,
        "straight": false
      },
      "number": {
        "density": {
          "enable": true,
          "area": 1000
        },
        "value": 50
      },
      "opacity": {
        "value": 0.2
      },
      "shape": {
        "type": "circle"
      },
      "size": {
        "value": {
          "min": 1,
          "max": 3
        }
      }
    },
    "detectRetina": true
  }


export default particlesConfig;