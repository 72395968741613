import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, NavLink } from 'react-router-dom';

const home = (
    <Tooltip id="home">
        Home
    </Tooltip>
);
// const exchange = (
//     <Tooltip id="exchange">
//         Exchange
//     </Tooltip>
// );
const accounts = (
    <Tooltip id="accounts">
        Accounts
    </Tooltip>
);
const data = (
    <Tooltip id="data">
        Connect to Exchange
    </Tooltip>
);
// const settings = (
//     <Tooltip id="settings">
//         Setting
//     </Tooltip>
// );

function Sidebar() {

    return (
        <>
            <div className="sidebar">
                <div className="menu">
                    <ul>
                        <li>
                            <NavLink to={"/dashboard/home"}>
                                <OverlayTrigger placement="right" overlay={home}>
                                    <span><i className="mdi mdi-view-dashboard"></i></span>
                                </OverlayTrigger>
                            </NavLink>
                        </li>
                        {/* <li>
                            <Link to={"./exchange"} activeClassName="active">
                                <OverlayTrigger placement="right" overlay={exchange}>
                                    <span><i className="mdi mdi-tumblr-reblog"></i></span>
                                </OverlayTrigger>
                            </Link>
                        </li> */}
                        <li>
                            <NavLink to={"/dashboard/account-overview"} className={(navData) => navData.isActive ? "active" : "" }>
                                <OverlayTrigger placement="right" overlay={accounts}>
                                    <span><i className="mdi mdi-face-profile"></i></span>
                                </OverlayTrigger>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/dashboard/connect-to-exchange"} className={(navData) => navData.isActive ? "active" : "" }>
                                <OverlayTrigger placement="right" overlay={data}>
                                    <span><i className="mdi mdi-database"></i></span>
                                </OverlayTrigger>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to={"/dashboard/settings"} className={(navData) => navData.isActive ? "active" : "" }>
                                <OverlayTrigger placement="right" overlay={settings}>
                                    <span><i className="mdi mdi-settings"></i></span>
                                </OverlayTrigger>
                            </NavLink>
                        </li> */}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar;