import React, { useContext, useState, useEffect } from "react"
import { auth } from "./firebase"
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  updateEmail,
  signOut,
} from "firebase/auth";
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../auth/firebase';
import axios from "axios";

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [currentUserData, setCurrentUserData] = useState({})
  const [totalUserBalance, setTotalUserBalance] = useState({})
  const [loading, setLoading] = useState(true)
  const [coinStats, setCoinStats] = useState({})

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  function updateUserEmail(email) {
    return updateEmail(currentUser, email)
  }

  function updateUserPassword(password) {
    return updatePassword(currentUser, password)
  }

  const getBTCandETHStats = async () => {
    await axios.get("/api/get-btc-and-eth-prices")
      .then(res => {setCoinStats(res.data)})
  }

  const getUserTotalBalance = async () => {
    let cud;
    if (JSON.stringify(currentUserData) === '{}') cud = await fetchUserData();
    else cud = currentUserData;

    try {
      if (typeof cud.membership !== 'undefined' && cud.membership.planType === 'demo') {
        await axios.get("/api/gather-user-total-balance", { params: { apiKey: "demo", apiSecret: "demo" } })
          .then(res => { setTotalUserBalance(res.data) })
          .catch(() => setTotalUserBalance({}))
      } else if (typeof cud.exchangeAPIKey !== 'undefined' && cud.exchangeAPIKey !== '') {
        await axios.get("/api/gather-user-total-balance", { params: { apiKey: cud.exchangeAPIKey, apiSecret: cud.exchangeAPISecret } })
          .then(res => { setTotalUserBalance(res.data) })
          .catch(() => setTotalUserBalance({}))
      } else {
        setTotalUserBalance({})
      }
    } catch {
      setTotalUserBalance({})
    }
  }

  const fetchUserData = async () => {
    let _currentUserData = {}
    await getDoc(doc(db, "users", currentUser.uid)).then(val => { _currentUserData = val.data() })
    setCurrentUserData(() => _currentUserData)
    return _currentUserData;
  }

  const updateUserData = async () => {
    fetchUserData();
    getUserTotalBalance();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    getBTCandETHStats();
    const interval1 = setInterval(() => {
      getBTCandETHStats({});
    }, 5000);

    return () => { unsubscribe, clearInterval(interval1) };
  }, [])

  useEffect(() => {
    if (currentUser) {
      fetchUserData()
      getUserTotalBalance();
      // Error: Way too much request weight used; IP banned until 1700219337355. Please use WebSocket Streams for live updates to avoid bans.
      const interval2 = setInterval(() => {
        getUserTotalBalance();
      }, 15000);
      return () => { clearInterval(interval2) };
    }

  }, [currentUser])

  const value = {
    currentUser,
    currentUserData,
    coinStats,
    totalUserBalance,
    updateUserData,
    fetchUserData,
    login,
    signup,
    logout,
    resetPassword,
    updateUserEmail,
    updateUserPassword
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}