import React from "react";
import { Link } from "react-router-dom";
// import { Row, Col, Card } from 'react-bootstrap'

function Footer1() {
    return (
        <>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="copyright">
                                <p>
                                    © Copyright {new Date().getFullYear()}{" "}
                                    <strong className="text-primary">NeuralTraders</strong> I All Rights
                                    Reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer1;
