import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
// import AccountSubmenu from "../layout/account-submenu";
import Footer2 from "../layout/footer2";
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import { useAuth } from '../auth/auth-context';
// import TimeDatePicker from '../element/datepicker';
import { setKeyOfUserDocument } from "../auth/firebase";
import { asyncTimeout } from "../../helper_functions";
import BottomNavbar from "../layout/bottom-navbar";
import { LazyLoadImage } from "react-lazy-load-image-component";

function AccountOverview() {
    const { currentUserData, updateUserPassword, currentUser, totalUserBalance } = useAuth()
    const [coinBalances, setCoinBalances] = useState({ "usdt": 0, "btc": 0, "eth": 0 })
    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const newPasswordRef = useRef()
    const newPasswordConfirmationRef = useRef()
    const [piChangeError, setPIChangeError] = useState("")
    const [pwChangeError, setPWChangeError] = useState("")
    const [loading, setLoading] = useState(false)
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    const handlePersonalInformationChange = async (e) => {
        e.preventDefault()
        setLoading(true)
        setPIChangeError("")
        let needsUpdate = false;
        
        // Check that first name is shorter than 20 characters
        if (firstNameRef.current.value.length > 20) {
            setLoading(false)
            return setPIChangeError("First name must be shorter than 20 characters");
        }
        // Check that last name is shorter than 20 characters
        if (lastNameRef.current.value.length > 20) {
            setLoading(false)
            return setPIChangeError("Last name must be shorter than 20 characters");
        }

        // Check which PI were changed and add store them in one {}
        let personalInformationToChange = {}
        if (firstNameRef.current.value !== "") { personalInformationToChange.firstName = firstNameRef.current.value }
        if (lastNameRef.current.value !== "") { personalInformationToChange.lastName = lastNameRef.current.value }
        if (JSON.stringify(personalInformationToChange) !== "{}") {
            await setKeyOfUserDocument(currentUser.uid, personalInformationToChange).then(() => needsUpdate = true)
                .catch(() => {
                    setLoading(false);
                    return setPIChangeError("Could not change Personal Information");
                })
        }
        
        if (needsUpdate) {
            await asyncTimeout(500);
            window.location.reload()
        }

        setLoading(false)
    }

    const handlePasswordChange = async (e) => {
        e.preventDefault()
        setLoading(true)
        setPWChangeError("")

        // If any of the strings empty
        if (newPasswordRef.current.value === "" ||
            newPasswordConfirmationRef.current.value === "") return setLoading(false)

        // If passwords don't match
        if (newPasswordRef.current.value !== newPasswordConfirmationRef.current.value) {
            setLoading(false);
            return setPWChangeError("Password do not match!")
        }

        // If new password is too simple
        if (newPasswordRef.current.value.length < 8) {
            setLoading(false);
            return setPWChangeError("Password must be at least 8 characters long!")
        }

        // Update the password
        await updateUserPassword(newPasswordRef.current.value)
            .catch(() => {
                setLoading(false);
                return setPWChangeError("Error occured during password update! Try to relog.")
            })

        
        setLoading(false);
        return setPWChangeError("")
    }

    useEffect(() => {
        if (JSON.stringify(totalUserBalance) !== '{}') {
            if (JSON.stringify(currentUserData) !== '{}') {
                let multiplier = 1;
                if (currentUserData.membership.planType === 'demo') {
                    multiplier = 100;
                }
                let usdt_val = 0;
                let btc_val = 0;
                let eth_val = 0;
                for (let coin of totalUserBalance.coins) {
                    if (coin.asset === 'USDT') {
                        usdt_val = coin.free * multiplier;
                    } else if (coin.asset === 'BTC') {
                        btc_val = coin.free * multiplier;
                    } else if (coin.asset === 'ETH') {
                        eth_val = coin.free * multiplier;
                    }
                }

                setCoinBalances({
                    usdt: usdt_val,
                    btc: btc_val,
                    eth: eth_val
                })
            }
        }
    }, [totalUserBalance])

    useEffect(() => {
        const onResize = () => {
            setWindowSize(window.innerWidth)
        }

        window.addEventListener("resize", onResize);
        return () => { window.removeEventListener("resize", onResize) };
    }, [])

    return (
        <>
            <Header2 />
            {windowSize >= 576 && <Sidebar />  }

            <div className="content-body" style={{"marginLeft": windowSize >= 576 ? "65px" : "6px"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-content px-3">
                                <p className="fs-4">Welcome Back,
                                <span className="text-primary"> {currentUserData.firstName} {currentUserData.lastName}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-12 col-md-6 mb-4">
                                    <div className="card h-100 px-4 pt-3">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <LazyLoadImage
                                                    className="me-3 rounded-circle me-0 me-sm-3 my-3"
                                                    src={require("../../images/profile/profile-picture.png")}
                                                    width="60"
                                                    height="60"
                                                    alt="User's profile picture for NeuralTraders"
                                                />
                                                <div className="media-body">
                                                    <span>Hello</span>
                                                    <h4 className="mb-2">{currentUserData.firstName} {currentUserData.lastName}</h4>
                                                    <p className="mb-1 fs-5">
                                                        {" "}
                                                        <span>
                                                            <i className="fa fa-envelope me-2 text-primary"></i>
                                                        </span>
                                                        {currentUserData.email}
                                                    </p>
                                                </div>
                                            </div>
                                            <ul className="card-profile__info mt-3">
                                                <li>
                                                    <h5 className="text-white me-4">
                                                        Date of Birth
                                                    </h5>
                                                    <span className="text-secondary">
                                                        {new Date(currentUserData.dateOfBirthTS).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}
                                                    </span>
                                                </li>
                                                <li>
                                                    <h5 className="text-white me-4">
                                                        Last Log
                                                    </h5>
                                                    <span className="text-secondary">
                                                        {new Date().toDateString()}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-4">
                                    <div className="card acc_balance h-100 px-2">
                                        <div className="card-header justify-content-start">
                                            <h3 className="card-title">Wallet {currentUserData.exchangeAPIKey === "" && <><Link to="/dashboard/connect-to-exchange"><strong className="text-danger mx-2">(Connect API first)</strong></Link></>}</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <span className="fs-5">Available USDT</span>
                                                    <h3>{coinBalances.usdt.toFixed(2)} USDT</h3>
                                                </div>
                                                <div>
                                                    <p className="mb-1 fs-5">Available BTC</p>
                                                    <h4>{coinBalances.btc.toFixed(7)} BTC</h4>

                                                    <p className="mb-1 fs-5">Available ETH</p>
                                                    <h4>{coinBalances.eth.toFixed(7)} ETH</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Personal Information</h4>
                                        </div>
                                        <div className="card-body">
                                            <form method="post" name="myform" className="personal_validate" onSubmit={handlePersonalInformationChange}>
                                                <div className="row">
                                                    {piChangeError && <div className="my-2 text-danger fs-5">{piChangeError}</div>}
                                                    <div className="mb-3 col-xl-6 col-md-6">
                                                        <label className="form-label">First Name</label>
                                                        <input type="text" className="form-control" placeholder={currentUserData.firstName}
                                                            name="firstName" ref={firstNameRef}/>
                                                    </div>
                                                    <div className="mb-3 col-xl-6 col-md-6">
                                                        <label className="form-label">Last Name</label>
                                                        <input type="text" className="form-control" placeholder={currentUserData.lastName}
                                                            name="lastName" ref={lastNameRef} />
                                                    </div>
                                                    {/* <div className="mb-3 col-xl-6 col-md-6">
                                                        <label className="form-label">Email</label>
                                                        <input type="email" className="form-control"
                                                            placeholder={currentUserData.email} name="email" ref={emailRef} />
                                                    </div> */}
                                                

                                                    <div className="mb-3 col-12">
                                                        <button className="btn btn-success px-4" disabled={loading}>Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Change Password</h4>
                                        </div>
                                        <div className="card-body">
                                            <form onSubmit={handlePasswordChange}>
                                                <div className="row">
                                                    {piChangeError && <div className="my-2 text-danger fs-5">{pwChangeError}</div>}
                                                    <div className="mb-3 col-xl-12">
                                                        <label className="form-label">New Password</label>
                                                        <input type="password" className="form-control"
                                                            placeholder="**********" ref={newPasswordRef}/>
                                                    </div>
                                                    <div className="mb-3 col-xl-12">
                                                        <label className="form-label">New Password Confirmation</label>
                                                        <input type="password" className="form-control"
                                                            placeholder="**********" ref={newPasswordConfirmationRef}/>
                                                    </div>
                                                    <div className="col-12">
                                                        <button className="btn btn-success waves-effect px-4" disabled={loading}>Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {windowSize < 576 && <BottomNavbar />  }
            <Footer2 />
        </>
    );
}

export default AccountOverview;
