import React from "react";
import Chart from "react-apexcharts";
import { month_map } from "../../helper_functions";

function CumulativeProfit({ botPerformance }) {

  const computeCumulativeProfit = () => {
    let _series = []
    for (let ct of botPerformance.closed_trades) {
      _series.push({ 'x': new Date(ct.close_date), 'y': ct.close_profit_abs })
    }
    _series.sort((a, b) => a.x - b.x);
    // console.log(_series)

    // Calculate cumulative profit
    let cumulativeSum = 0;
    let series = [];
    _series.forEach((item) => {
      // console.log(typeof item.x)
      // item.x = `${month_map[item.x.getMonth()]} ${item.x.getDate()}`
      // console.log(item.x)
      cumulativeSum += item.y;
      // item.y = cumulativeSum;
      series.push({x: item.x, y: cumulativeSum})
    });
    return series;
  }

  const getSeries = () => {
    if (JSON.stringify(botPerformance) === '{}') {
      return [];
    } else {
      return [{
        name: 'Cumulative Profit',
        data: computeCumulativeProfit()
      }]
    }
  }

  const chart_options = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        type: "datetime",
        tickAmount: 3,
        // labels: {
        //   formatter: function (value, timestamp) {
        //     console.log(value)
        //     return value // The formatter function overrides format property
        //   }, 
        // }
        labels: {
          rotate: 0,
          format: 'MMM dd',
          // datetimeFormatter: {
          //   year: 'yyyy',
          //   month: 'MMM \'yy',
          //   day: 'dd MMM',
          //   hour: 'HH:mm'
          // },
          style: {
            colors: '#d4d4d4', // Customize the color of the x-axis labels
          },
        }
      },
      yaxis: {
        labels: {
          formatter: (value) => { return value.toFixed(2) },
          style: {
            colors: '#d4d4d4', // Customize the color of the y-axis labels
          },
        },
        
        title: {
          text: 'Profit ($)', // Set the label for the y-axis
          style: {
            color: "#ffffff",
          },
        },
      },
      grid: {
        borderColor: '#484e5c',
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 4,
        dashArray: 0,
      },
      colors: ['#5d78ff'],
      dataLabels: {
        enabled: false
      },
      noData: {
        text: "Data loading...",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "white",
          fontSize: '14px',
          fontFamily: undefined
        }
      }
    },
    series: getSeries()
  }


  window.dispatchEvent(new Event('resize'));
  return (
    <>
      <Chart
        options={chart_options.options}
        series={chart_options.series}
        type="line"
      />
    </>
  );
}

export default CumulativeProfit;