import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './css/style.css';
import "./App.css";

// ReactDOM.hydrateRoot(
//   document.getElementById('root'),
//   <React.StrictMode>
//     <div className="App">
//       <App />
//     </div>
//   </React.StrictMode>
// )

// const container = document.getElementById(“app”);
// const root = createRoot(container);
// root.render(<App />);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <div className="App">
      <App />
    </div>
  </React.StrictMode>,
)
