import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth/auth-context';
import { useNavigate } from 'react-router-dom';
import HeaderBtcEthStats from '../element/header-btc-eth-stats';


// Images
import { LazyLoadImage } from "react-lazy-load-image-component";
import IMGCompanyLogo from '../../images/neuraltraders-logo-text.png'
import IMGCompanyLogoSmall from '../../images/neuraltraders-logo-face.png'

const ProfileToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}

    >
        {children}
        <div className="profile_log">
            <div className="user">
                <span className="thumb"><i className="mdi mdi-account"></i></span>
                <span className="arrow"><i className="la la-angle-down"></i></span>
            </div>
        </div>
    </div>
));

function Header2() {
    const { currentUserData, logout } = useAuth()
    const navigate = useNavigate()
    const [membershipStatus, setMembershipStatus] = useState(<></>)
    const [buySubscriptionButton, setBuySubscriptionButton] = useState(<></>)

    const customMembershipWidget = (membership) => {
        if (membership.planType === 'demo') {
            setBuySubscriptionButton(
                <Link to={"/pricing"} className="dropdown-item fs-5 text-success">
                    <i className="mdi mdi-currency-usd text-success"></i> Buy Subscription
                </Link>
            )
            return setMembershipStatus(<Link to="/pricing" className="btn btn-outline-warning">Demo</Link>);}
        try {
            if (membership.planType.startsWith("basic")) {
                return setMembershipStatus(<Link className="btn btn-outline-success">Basic</Link>);
            } else if (membership.planType.startsWith("pro")) {
                return setMembershipStatus(<Link className="btn btn-outline-success">Pro</Link>);
            }
        } catch {
            return setMembershipStatus(<Link className="btn btn-outline-warning">Expired</Link>);
        }
    }

    useEffect(() => {
        if (JSON.stringify(currentUserData) !== '{}') {
            customMembershipWidget(currentUserData.membership)
        } else setMembershipStatus(<></>)
    }, [currentUserData])

    const handleLogout = async () => {
        try {
            await logout()
            navigate('/');
        } catch {
            console.log("Failed to log out")
        }
    }

    return (
        <>
            <div className="header dashboard">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <nav className="navbar navbar-expand-lg navbar-light px-0 justify-content-between">
                                <Link className="navbar-brand d-lg-none" to={"/dashboard/home"}><LazyLoadImage className="mx-2" src={IMGCompanyLogoSmall} alt="NeuralTraders Logo" style={{ "maxHeight": "40px" }} /></Link>
                                <Link className="navbar-brand d-none d-lg-block" to={"/dashboard/home"}><LazyLoadImage className="mx-2" src={IMGCompanyLogo} alt="NeuralTraders Logo" style={{ "maxHeight": "40px" }} /></Link>
                                <HeaderBtcEthStats />

                                <div className="header-right d-flex my-2 align-items-center">
                                    {currentUserData &&
                                        <div className="">
                                            {membershipStatus}
                                        </div>}
                                    <div className="mx-4">
                                        <Link to="/" className='fs-4'>Back to Home</Link>
                                    </div>
                                    <div className="dashboard_log">
                                        <Dropdown size="lg" className="profile_log">
                                            <Dropdown.Toggle size="lg" as={ProfileToggle} />
                                            <Dropdown.Menu size="lg" title="">
                                                <div className="user-email">
                                                    <div className="user">
                                                        <span className="thumb"><i className="mdi mdi-account"></i></span>
                                                        <div className="user-info">
                                                            <h4 className='mx-2 mb-0'>{currentUserData ? currentUserData.firstName : ""} {currentUserData ? currentUserData.lastName : ""}</h4>
                                                            <span className='mx-2 fs-4'>{currentUserData ? currentUserData.email : ""}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="user-balance">
                                                    <div className="available">
                                                        <p className='fs-5'>Available</p>
                                                        <span className='fs-5'>--.-- USD</span>
                                                    </div>
                                                    <div className="total">
                                                        <p className='fs-5'>Total</p>
                                                        <span className='fs-5'>--.-- USD</span>
                                                    </div>
                                                </div> */}

                                                {/* TODO: change to dynamically load balance for the user */}
                                                <Link to={"/dashboard/home"} className="dropdown-item fs-5">
                                                    <i className="mdi mdi-view-dashboard"></i> Dashboard
                                                </Link>
                                                <Link to={"/dashboard/account-overview"} className="dropdown-item fs-5">
                                                    <i className="mdi mdi-account"></i> Account
                                                </Link>
                                                {/* <Link to={"./data-tbi"} className="dropdown-item">
                                                    <i className="mdi mdi-history"></i> History
                                                </Link> */}
                                                <Link to={"/dashboard/connect-to-exchange"} className="dropdown-item fs-5">
                                                    <i className="mdi mdi-settings"></i> Connect to Exchange
                                                </Link>
                                                {/* <Link to={"./lock"} className="dropdown-item">
                                                    <i className="mdi mdi-lock"></i> Lock
                                                </Link> */}
                                                {buySubscriptionButton}
                                                <Link className="dropdown-item logout fs-5" onClick={handleLogout}>
                                                    <i className="mdi mdi-logout"></i> Logout
                                                </Link>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header2;