import React, { useEffect, useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { useAuth } from "../auth/auth-context"
import ProfileSnippet from '../element/profile-snippet';

// Images
import IMGCompanyLogo from '../../images/neuraltraders-logo-text.png'
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function CustomNavbar() {
    const { currentUser } = useAuth()
    const location = useLocation()
    const [showNavbar, setShowNavbar] = useState(true)
    const [navExpanded, setNavExpanded] = useState(false)

    useEffect(() => {
        if (location.pathname.startsWith('/dashboard')) {
            setShowNavbar(false)
        } else {
            setShowNavbar(true)
        }
    }, [location])

    // If we are on the pages related to Dashboard, do not show Navbar
    if (showNavbar) {
        return (
            <>
                <div className="header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="navigation">
                                    <Navbar bg="light" expand="lg" collapseOnSelect>
                                        <Link className="navbar-brand mx-2" to={'/'}><LazyLoadImage src={IMGCompanyLogo} alt="NeuralTraders Logo" style={{"maxHeight": "40px"}}/></Link>
                                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                                        <Navbar.Collapse id="basic-navbar-nav" style={{"fontSize": "17px"}}>
                                            <Nav className="ms-auto">
                                                <Nav.Item><Nav.Link className="nav-Nav.link text-white" as={Link} to="/" href='#' style={{"fontSize": "17px"}}>Home</Nav.Link></Nav.Item>
                                                <Nav.Item><Nav.Link className="nav-Nav.link text-white text-nowrap" as={Link} href='#' to="/trading-bots" style={{"fontSize": "17px"}}>Trading bots</Nav.Link></Nav.Item>
                                                <Nav.Item><Nav.Link className="nav-Nav.link text-white" as={Link} to="/pricing" href='#' style={{"fontSize": "17px"}}>Pricing</Nav.Link></Nav.Item>
                                                <Nav.Item><Nav.Link className="nav-Nav.link text-white text-nowrap" as={Link} href='#' to="/about-us" style={{"fontSize": "17px"}}>About Us</Nav.Link></Nav.Item>
                                                <Nav.Item>
                                                    {currentUser && <ProfileSnippet />}
                                                    {/* {!currentUser && <Link to="/login" href='#' className="btn btn-primary ms-3 text-nowrap text-white">Log In</Link>} */}
                                                    {!currentUser && <Nav.Link className="btn btn-primary text-nowrap text-white" as={Link} to="/login" href='#'>Log In</Nav.Link>}
                                                </Nav.Item>
                                            </Nav>
                                        </Navbar.Collapse>
                                        {/* Add profile snippet or Log In (or Sign Up) button */}
                                        {/* <div className="signin-btn">
                                            {currentUser && <ProfileSnippet />}
                                            {!currentUser && <Link to="/login" className="btn btn-primary ms-3">Log In</Link>}
                                        </div> */}
                                    </Navbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return <></>
    }
}
