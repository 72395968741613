import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, NavLink } from 'react-router-dom';


function BottomNavbar() {

    return (
        <>
            <nav className="navbar fixed-bottom navbar-dark bg-dark justify-content-center" style={{borderTop: "5px solid #000000", 'padding': '20px 0'}}>
                <ul className="nav justify-content-center nav-pills">
                    <li className="nav-item mx-4">
                        <a className="nav-link bg-primary" href="/dashboard/home"><span><i className="mdi mdi-view-dashboard text-white fs-4"></i></span></a>
                    </li>
                    <li className="nav-item mx-4">
                        <a className="nav-link bg-primary" href="/dashboard/account-overview"><span><i className="mdi mdi-face-profile text-white fs-4"></i></span></a>
                    </li>
                    <li className="nav-item mx-4">
                        <a className="nav-link bg-primary" href="/dashboard/connect-to-exchange"><span><i className="mdi mdi-database text-white fs-4"></i></span></a>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default BottomNavbar;