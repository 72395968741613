import { setKeyOfUserDocument } from '../auth/firebase';
import React, { useState, useRef } from 'react';
import { useAuth } from '../auth/auth-context';
import Header2 from '../layout/header2';
import Sidebar from '../layout/sidebar';
import Footer2 from '../layout/footer2';
import { useEffect } from 'react';
import axios from 'axios';
import BottomNavbar from "../layout/bottom-navbar";

// images
import account_setup_character from '../../icons/account-setup-character.png'
import { LazyLoadImage } from "react-lazy-load-image-component";

function ConnectToExchange() {
    const apiKeyRef = useRef()
    const apiSecretRef = useRef()
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(". . .")
    const { currentUser } = useAuth()
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    const binanceVerificationProcess = async (apiKey, apiSecret) => {
        setStatus("INFO: Verifying API connection. Please wait...")
        let verificationResponse = {}
        await axios.get("/api/binance-verify", {
            params: { apiKey: apiKey, apiSecret: apiSecret }
        })
            .then(res => { verificationResponse = res })
            .catch(() => setStatus("ERROR occured. Please contact us..."))

        if (verificationResponse.data.code === -2008) {
            setStatus("ERROR: Wrong API credentials!")
            return false;
        } else if (verificationResponse.data.code === -2015) {
            setStatus("ERROR: Please set 'IP access' to 'Unrestricted'")
            return false;
        }

        const verification_outcome = {
            enableReading: verificationResponse.data.enableReading,
            ipUnrestrict: !verificationResponse.data.ipRestrict,
            enableSpot: verificationResponse.data.enableSpotAndMarginTrading,
        }

        for (let key in verification_outcome) {
            if (!verification_outcome[key]) {
                if (key == "enableReading") {
                    setStatus("ERROR: Please 'Enable Reading' on Binance API")
                } else if (key == "ipUnrestrict") {
                    setStatus("ERROR: Please set 'IP access' to 'Unrestricted'")
                } else if (key == "enableSpot") {
                    setStatus("ERROR: Please 'Enable Spot & Margin Trading' on Binance API")
                }
                return false;
            }
        }
        setStatus("INFO: Checking your balance...")
        let totalUserBalance;
        await axios.get("/api/gather-user-total-balance", {params: { apiKey: apiKey, apiSecret: apiSecret }})
            .then(res => { totalUserBalance = res.data })
            .catch(() => setStatus("ERROR occured. Please contact us..."))
        
        for (let assetEntry of totalUserBalance.coins) {
            if (assetEntry.asset === 'USDT') {
                let freeUsdtBalance = Number(assetEntry.free)
                if (freeUsdtBalance === 0) {
                    setStatus("ERROR: Your spot USDT balance is 0$. Top it up to continue.")
                    return false;
                } else if (freeUsdtBalance < 10) {
                    setStatus("ERROR: Your spot USDT balance is below 10$. Top it up to continue.")
                    return false;
                } else {
                    setStatus("Success")
                    return true;
                }
            }
        }
        setStatus("ERROR occured. Please contact us...")
        return false;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (apiKeyRef.current.value && apiSecretRef.current.value) {
            let resultOfVerificationProcess;
            await binanceVerificationProcess(apiKeyRef.current.value, apiSecretRef.current.value)
                .then(isVerified => resultOfVerificationProcess = isVerified)

            if (resultOfVerificationProcess) {
                await setKeyOfUserDocument(currentUser.uid,
                            {
                                exchangeToUse: 'binance',
                                exchangeAPIKey: apiKeyRef.current.value,
                                exchangeAPISecret: apiSecretRef.current.value,
                            })
            }
        }
        setLoading(false);
    }

    const renderStatusbar = () => {
        if (status.startsWith('ERROR')) {
            return <div className="alert alert-danger">{status}</div>
        } else if (status.startsWith('INFO')) {
            return <div className="alert alert-info">{status}</div>
        } else if (status === "Success") {
            return <div className="alert alert-success">{status}</div>
        } else if (status.startsWith(".")) {
            return <div className="alert alert-info">{status}</div>
        } else {
            return <div className="alert alert-info">. . .</div>
        }
    }

    const status_loading_timer = () => {
        switch (status) {
            case ". . .":
                setStatus(".")
                break;
            case ". .":
                setStatus(". . .")
                break;
            case ".":
                setStatus(". .")
                break;
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => !loading && status_loading_timer(status), 500)
        return () => clearTimeout(timer)
    }, [status, loading])

    useEffect(() => {
        const onResize = () => {
            setWindowSize(window.innerWidth)
        }

        window.addEventListener("resize", onResize);
        return () => { window.removeEventListener("resize", onResize) };
    }, [])

    return (
        <>
            <Header2 />
            {windowSize >= 576 && <Sidebar />  }

            <div className="content-body" style={{"marginLeft": windowSize >= 576 ? "65px" : "6px"}}>
                <div className="container">
                    <div className="card text-center">
                        <h4 className="text-white pt-3">How to Connect Our Bot to Your Crypto Exchange Account</h4>
                        <div className="card-header pb-0 m-0"></div>
                        <div className="card-body fs-5">
                            <div className="row justify-content-md-center text-start mt-4">
                                <div className="col-12 col-lg-5 text-center">
                                    <LazyLoadImage className="img-fluid" style={{ 'maxWidth': '80%' }} src={account_setup_character} alt='Male character showing how to connect NeuralTraders' />
                                </div>
                                <div className='col-12 col-lg-1'></div>
                                <div className="col-12 col-lg-6 pt-4">
                                    <p className='text-light'>We provide a <a href='/setup-guide'>detaile guide</a> on how to connect our bot to your Binance account. Please read it carefully before continuing.</p>
                                    <p className='text-light'>Connecting our trading bot to your crypto exchange account is a seamless process. Follow these step-by-step instructions to establish the link, enabling automated trading strategies to execute on your behalf.</p>
                                    <ol className="list-group list-group-numbered mb-4">
                                        <li className="list-group-item text-light">
                                            Create an account on Binance.
                                        </li>
                                        <li className="list-group-item text-light">
                                            Top up your balance with amount you are willing to trade (min. 10$). The optimal bot's performance is reached when your balance is $250 and above.
                                        </li>
                                        <li className="list-group-item text-light">
                                            Create an API key and secret, and add permissions specified in the guide.
                                        </li>
                                        <li className="list-group-item text-light">
                                            Clicking 'Submit' will start a verification process. If successful, your credentials will be updated.
                                        </li>
                                    </ol>
                                    <p className='text-light'>Please follow <a href='/setup-guide'>this guide</a> for detailed instructions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card text-center h-100">
                        <div className="card-body">
                            <div className="row text-start mt-4">
                                <form>
                                    <div className="row">
                                        <div className='col-5'>
                                            <div className="mb-3 col-xl-12">
                                                <label className="form-label text-primary">Binance API Key</label>
                                                <input className="form-control" placeholder="Enter your API key" ref={apiKeyRef} />
                                                {/* <input className="form-control" placeholder="Enter your API key" ref={apiKeyRef} value={"asd"} /> */}
                                            </div>
                                            <div className="mb-3 col-xl-12">
                                                <label className="form-label text-primary">Binance API Secret</label>
                                                <input className="form-control" placeholder="Enter your API secret" ref={apiSecretRef} />
                                            </div>
                                        </div>
                                        <div className='col-1'></div>
                                        <div className='col-4 my-auto'>
                                            <div className='row'>
                                                {renderStatusbar()}
                                                <button className="btn btn-primary btn-lg waves-effect px-4" disabled={loading} onClick={handleSubmit}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-4'></div>

            {windowSize < 576 && <BottomNavbar />  }
            <Footer2 />
        </>
    )
}

export default ConnectToExchange;