import React, {useCallback} from 'react'
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import particlesConfig from '../element/ParticlesConfig';
import Bottom from '../element/bottom';
import Footer1 from '../layout/footer1';
import { Link } from 'react-router-dom';

export default function PurchaseCancelled() {

    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    return (
        <>
            <Particles id="particles" init={particlesInit} options={particlesConfig} />
            <div className="intro my-4" style={{"height": "500px"}}>
                <div className="container text-center">
                    <h1 className='my-4'>Something went wrong during your purchase.</h1>
                    <Link to="/" className="btn btn-primary my-4 mx-4 fs-4 px-3 py-2"><strong className='text-white'>Go to Home</strong></Link>
                </div>
            </div>
            <Bottom />
            <Footer1 />
        </>)
}