import React from 'react'
import { useAuth } from "../auth/auth-context"

export default function HeaderBtcEthStats() {
    const { coinStats } = useAuth()

    if (JSON.stringify(coinStats) === '{}') {
        return <div></div>
    } else {
        return (
            <div className="header-right my-2 align-items-center fs-6 d-none d-md-flex">
                {Number(coinStats.btc_data.priceChange) > 0 ?
                    <div className='mx-2'>
                        BTC/USDT <strong className='text-success'>${Number(coinStats.btc_data.lastPrice).toFixed(1)}
                            ({Number(coinStats.btc_data.priceChangePercent).toFixed(2)}%)</strong>
                    </div> :
                    <div className='mx-2'>
                        BTC/USDT <strong className='text-warning'>${Number(coinStats.btc_data.lastPrice).toFixed(1)}
                            ({Number(coinStats.btc_data.priceChangePercent).toFixed(2)}%)</strong>
                    </div>}
                {Number(coinStats.eth_data.priceChange) > 0 ?
                    <div className='mx-2'>
                        ETH/USDT <strong className='text-success'>${Number(coinStats.eth_data.lastPrice).toFixed(1)}
                            ({Number(coinStats.eth_data.priceChangePercent).toFixed(2)}%)</strong>
                    </div> :
                    <div className='mx-2'>
                        ETH/USDT <strong className='text-warning'>${Number(coinStats.eth_data.lastPrice).toFixed(1)}
                            ({Number(coinStats.eth_data.priceChangePercent).toFixed(2)}%)</strong>
                    </div>}
            </div>
        )
    }
}
