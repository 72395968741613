import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"
import { firebaseConfig } from './firebase.config';
import {
    getDocs,
    getDoc,
    setDoc,
    collection,
    addDoc,
    deleteDoc,
    updateDoc,
    doc,
    query,
    orderBy,
    limit
  } from "firebase/firestore";

const app = initializeApp(firebaseConfig)

// export const auth = app.auth()
export const auth = getAuth(app)
export const db = getFirestore(app)
export default app

export const createUserDocument = async (user) => {
    if (!user) return;

    const userRef = doc(db, "users", user.uid)
    const snapshot = await getDoc(userRef)

    if (!snapshot.exists()) {
        const {email, firstName, lastName, isUSsitizen, dateOfBirthTS} = user;
        try {
            // Get last port number
            const usersRef = collection(db, "users");
            const q = query(usersRef, orderBy("port", "desc"), limit(1));
            const querySnapshot = await getDocs(q);
            let lastPortNumber = -1;
            querySnapshot.forEach((doc) => {
                lastPortNumber = doc.data().port;
            })
            setDoc(userRef, {
                uid: user.uid,
                email: email,
                firstName: firstName,
                lastName: lastName,
                createdAt: new Date(),
                exchangeToUse: "",
                exchangeAPIKey: "",
                exchangeAPISecret: "",
                membership: {planType: 'demo'},
                profilePicture: "",
                port: lastPortNumber + 1,
                dateOfBirthTS: dateOfBirthTS,
                isUSsitizen: isUSsitizen,
                botStatus: "created"
            })
        } catch(error) {
            console.log("Error in creating user", error)
        }
    } else {
        console.log("ERROR: User already exists!")
    }
}

export const setKeyOfUserDocument = async (uid, params) => {
    const userRef = doc(db, "users", uid)
    const snapshot = await getDoc(userRef)
    
    if (snapshot.exists()) {
        try {
            // console.log(params)
            // let params_to_change = {}
            // for (let key in params) {
            //     params_to_change[key] = params[key]
            // }
            // console.log(params_to_change)
            setDoc(userRef, params, { merge: true });
        } catch(error) {
            console.log("Could not update the user info.", error)
        }
    } else {
        console.log("ERROR: User doesn't exists!")
    }
}