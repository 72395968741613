import React, {useCallback} from 'react';
import { Link } from 'react-router-dom';
import Footer1 from '../layout/footer1';
import Bottom from './../element/bottom';
import { Accordion } from 'react-bootstrap';
import PricingTable from '../element/pricing-table';

// Images
import IMGDasboard from '../../images/neuraltraders-dashboard.png'
import IMGBannerVector from '../../images/neural-traders-expected-monthly-return.png'
import IMGAboutImg from '../../images/about-neural-traders.png'
import { useAuth } from '../auth/auth-context';
import { LazyLoadImage } from "react-lazy-load-image-component";


import Particles from "react-particles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import particlesConfig from '../element/ParticlesConfig';

function Homepage() {
    const {currentUser} = useAuth()

    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    return (
        <>
            <Particles id="particles" init={particlesInit} options={particlesConfig} />
            {/* Home */}
            <div className="intro" id="home">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-xl-5 col-lg-5 col-12 px-4">
                            <div className="intro-content">
                                <h1 style={{"fontWeight": "normal"}}>
                                    <strong className="text-primary "style={{"fontWeight": "bold"}}>Algorithmic Trading </strong>Made Simple With <strong className="text-primary" style={{"fontWeight": "bold"}}>NeuralTraders</strong>.
                                    {/* <br /> Buy and sell cryptocurrency */}
                                </h1>
                                {/* <h2>Start earning today!</h2> */}
                                <p>Experience Profitable Trading with Simple and Effective Algorithms.</p>
                            </div>

                            <div className="intro-btn pb-4">
                                <Link to={currentUser ? '/dashboard/home' : '/signup'} className="btn btn-primary my-2">{currentUser ? 'DASHBAORD' : 'START FREE'}</Link>
                                <a href='#portfolio' className="btn btn-outline-primary my-2">BROWSE NOW</a>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12">
                            <LazyLoadImage src={IMGBannerVector} className='w-100' alt='NeuralTraders pitch photo showing great monthly returns' />
                        </div>
                    </div>
                </div>
            </div>

            {/* 4 */}
            <div className="portfolio section-padding" id="portfolio">
                <div className="container">
                    <div className="row py-lg-5 justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h2>How It Works?</h2>
                                <p>At the heart of our mission is your financial security. Say hello to NeuralTraders, your trusty crypto companion. Seamlessly link our cutting-edge bot to your exchange account, and keep tabs on its performance 24/7, ensuring peace of mind and potential profits in every trade!</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        {/* <div className="col-xl-7 col-lg-6"> */}
                        <div className="col-12">
                            <div className="portfolio_list">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="d-flex">
                                            <span className="port-icon"> <i className="la la-user-plus text-white"></i></span>
                                            <div className="flex-grow-1">
                                                <h4 className='text-primary'>Create Account</h4>
                                                <p>The first step to begin your NeuralTraders journey is to create an account on our platform.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="d-flex">
                                            <span className="port-icon"> <i className="la la-calendar text-white"></i></span>
                                            <div className="flex-grow-1">
                                                <h4 className='text-primary'>Choose the Subscription</h4>
                                                <p>Try it for free and choose a subscription to enhance your trading and maximize profits.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="d-flex">
                                            <span className="port-icon"> <i className="la la-exchange text-white"></i></span>
                                            <div className="flex-grow-1">
                                                <h4 className='text-primary'>Connect to Exchange</h4>
                                                <p>Connect our bot to your exchange via API for a seamless, empowered trading experience.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="d-flex">
                                            <span className="port-icon"> <i className="la la-money text-white"></i></span>
                                            <div className="flex-grow-1">
                                                <h4 className='text-primary'>Start Earning Money</h4>
                                                <p>Generate income as you sleep. Watch your earnings grow effortlessly with NeuralTraders.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-5 col-lg-6">
                            <div className="portfolio_img">
                                <LazyLoadImage src={IMGPortfolio} alt="" className="img-fluid" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="abous-us section-padding" id="abous-us">
                <div className="container">
                    <hr ></hr>
                    <div className="row justify-content-between align-items-center mt-4">
                        <div className="col-xl-5 col-lg-6 col-12">
                            <LazyLoadImage src={IMGAboutImg} className='w-100' alt='NeuralTraders is algorithmic cryptocurency trading solution.' />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12">
                            <h2>What is the <strong className="text-primary">NeuralTraders</strong>?</h2>
                            <p className="h4 text-secondary pt-2">With a team of experts dedicated to innovation and performance, we aim to provide traders with the tools and knowledge needed to navigate the dynamic world of algorithmic trading successfully.</p>
                            <br />
                            <p className="h4 text-secondary">At NeuralTraders, we are passionate about leveraging cutting-edge technology to empower traders with algorithmic solutions that enhance their trading strategies.</p>
                            <br />
                            <div className="">
                                <Link to={currentUser ? '/dashboard/home' : '/signup'} className="btn btn-outline-primary btn-lg">{currentUser ? 'DASHBOARD' : 'START FREE'}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* 10 */}
            <div className="competetive-benefit section-padding">
                <div className="container">
                <hr ></hr>
                    <div className="row justify-content-center my-5">
                        <div className="col-xl-9 text-center mb-4">
                            <h2 className='text-white my-5'>Competitive Benefit</h2>
                            <p className='h4 text-center text-secondary mb-4'>Discover the features of smart algorithmic trading! While traditional investments like gold and real estate offered minimal returns, Bitcoin's unpredictable ups and downs left investors uncertain. Our solution, however, delivered stable and consistent returns on investment, free from any downturns. Embrace the future of trading with confidence and watch your investments grow steadily!</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-4 col-lg-2 mb-4'>
                            <div className="card text-center h-100">
                                <i className='la la-shield pt-3' style={{ "fontSize": "4rem", "color": "white" }}></i>
                                <div className='card-body'>
                                    <strong>Security and Trustworthiness</strong>
                                </div>
                            </div>
                        </div>
                        <div className='col-4 col-lg-2 mb-4'>
                            <div className="card text-center h-100">
                                <i className='la la-btc pt-3' style={{ "fontSize": "4rem", "color": "white" }}></i>
                                <div className='card-body'>
                                    <strong>Wide Range of Cryptocurrencies</strong>
                                </div>
                            </div>
                        </div>
                        <div className='col-4 col-lg-2 mb-4'>
                            <div className="card text-center h-100">
                                <i className='la la-credit-card pt-3' style={{ "fontSize": "4rem", "color": "white" }}></i>
                                <div className='card-body'>
                                    <strong>No extra payments required</strong>
                                </div>
                            </div>
                        </div>
                        <div className='col-4 col-lg-2 mb-4'>
                            <div className="card text-center h-100">
                                <i className='la la-area-chart pt-3' style={{ "fontSize": "4rem", "color": "white" }}></i>
                                <div className='card-body'>
                                    <strong>Steady Trading Balance Growth</strong>
                                </div>
                            </div>
                        </div>
                        <div className='col-4 col-lg-2 mb-4'>
                            <div className="card text-center h-100">
                                <i className='la la-laptop pt-3' style={{ "fontSize": "4rem", "color": "white" }}></i>
                                <div className='card-body'>
                                    <strong>User-Friendly Dashboard</strong>
                                </div>
                            </div>
                        </div>
                        <div className='col-4 col-lg-2 mb-4'>
                            <div className="card text-center h-100">
                                <i className='la la-hourglass-2 pt-3' style={{ "fontSize": "4rem", "color": "white" }}></i>
                                <div className='card-body'>
                                    <strong>Set Up Easily in 3 Steps</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <PricingTable /> */}
            <PricingTable />

            {/* 8 */}
            <div className="appss section-padding my-4" id="app">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-5">
                            <div className="appss-content">
                                <h2>Useful Dashboard</h2>
                                <ul>
                                    <li><i className="la la-check"></i> User-Friendly Interface</li>
                                    <li><i className="la la-check"></i> Automated Trading Strategies</li>
                                    <li><i className="la la-check"></i> Portfolio Tracking</li>
                                    <li><i className="la la-check"></i> Advanced Analytics</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="appss-img">
                                <LazyLoadImage className="img-fluid" src={IMGDasboard} alt="NeuralTraders dashboard picture showing bot functionality and performance" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* 6 */}
            {/* <div className="testimonial section-padding" id="testimonial">
                <div className="container">
                    <div className="row justify-content-center">
                        <hr ></hr>
                        <div className="col-xl-6 my-5">
                            <div className="text-center">
                                <h2>What our customer says</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <div className="testimonial-content">
                                <Testimonial />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* 7 */}
            <div className="faq section-padding py-5" id="faq">
                <div className="container">
                    <h2 className='text-white mb-5 text-center'>Frequenly Asked Questions <strong className='text-primary'>(F.A.Qs)</strong></h2>
                    <hr></hr>
                    <div className="row justify-content-center">
                        <Accordion className='text-light fs-5' bg="light">
                            <Accordion.Item eventKey="0" className='bg-secondary'>
                                <Accordion.Header><p className='fs-4 my-1'>What is an algorithmic trading bot?</p></Accordion.Header>
                                <Accordion.Body className='text-secondary '>
                                An algorithmic trading bot is a computer program that executes pre-programmed trading strategies automatically. It uses mathematical algorithms to analyze market data and make trading decisions without human intervention.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1" className='bg-secondary'>
                                <Accordion.Header><p className="fs-4 my-1">How does an algorithmic bot work?</p></Accordion.Header>
                                <Accordion.Body className='text-secondary '>
                                These bots monitor market indicators, such as price movements, volume, and other relevant data, and use predefined trading strategies to identify buying or selling opportunities. They can execute trades, manage risk, and even perform complex trading actions based on the programmed rules.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2" className='bg-secondary'>
                                <Accordion.Header><p className="fs-4 my-1">What are the benefits of using algorithmic trading bots in the cryptocurrency market?</p></Accordion.Header>
                                <Accordion.Body className='text-secondary '>
                                These bots monitor market indicators, such as price movements, volume, and other relevant data, and use predefined trading strategies to identify buying or selling opportunities. They can execute trades, manage risk, and even perform complex trading actions based on the programmed rules.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3" className='bg-secondary'>
                                <Accordion.Header><p className="fs-4 my-1">What are the risks associated with algorithmic trading bots?</p></Accordion.Header>
                                <Accordion.Body className='text-secondary '>
                                While bots can automate trading, they are not foolproof. Programming errors, technical glitches, or incorrect market assumptions can lead to significant losses. Market conditions and sudden price fluctuations can also affect performance. Therefore, careful monitoring and periodic adjustments are necessary.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4" className='bg-secondary'>
                                <Accordion.Header><p className="fs-4 my-1">Do I need coding skills to use NeuralTraders bots?</p></Accordion.Header>
                                <Accordion.Body className='text-secondary '>
                                No, as we provide everything you need including ready-to-use NeuralTraders bot and user-friendly dashboard, allowing even non-programmers to use bots easily
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>
                </div>
            </div>

            <Bottom />
            <Footer1 />
        </>
    )
}

export default Homepage;
