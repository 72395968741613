import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from "./auth-context"

// export default function PrivateRoute({ component: Component, ...rest }) {
//   const { currentUser } = useAuth()

//   return (
//     <Route
//       {...rest}
//       render={props => {
//         return currentUser ? <Component {...props} /> : <Navigate to="/" />
//       }}
//     ></Route>
//   )
// }

const PrivateRoute = () => {
  const { currentUser } = useAuth()

  if (currentUser) {
    return <Outlet />
  } else {
    return <Navigate to="/" replace />
  }
};

export default PrivateRoute;