import { useState } from "react"
import '../../images/cryptocurrency-icons/cryptocurrencies.css';

export function PaginatedTable({table_data_pages, closedTable}) {
    const [activePage, setActivePage] = useState(0)
    const lastPageIndex = Object.keys(table_data_pages)[Object.keys(table_data_pages).length - 1]

    const renderPagitionLI = (index, listValue) => {
        if (Object.keys(table_data_pages).length > 5){
            if ((Number(listValue) > activePage - 2) && (Number(listValue) <= activePage + 1)) {
                return <li className={Number(listValue) === activePage?"page-item active":"page-item"} key={index}><a className="page-link" onClick={() => setActivePage(Number(listValue))}>{Number(listValue)+1}</a></li>
            }
            if ((Number(listValue) == activePage + 2) & (Number(activePage + 1) < lastPageIndex)) {
                return <li className="page-item" key={index}><a className="page-link">...</a></li>
            }
            if ((Number(listValue) == activePage + 3) & (Number(activePage + 1) < lastPageIndex)) {
                return <li className="page-item" key={index}><a className="page-link" onClick={() => setActivePage(Number(lastPageIndex))}>{Number(lastPageIndex)+1}</a></li>
            }
        } else {
            return <li className={Number(listValue) === activePage?"page-item active":"page-item"} key={index} onClick={() => setActivePage(Number(listValue))}><a className="page-link">{Number(listValue)+1}</a></li>
        }
    }

    return (
        <>
            <div className="table-responsive" style={{"maxWidth": "100%", "overflowX": "auto"}}>
                <table className="table table-striped" id="tbUser">
                    <thead>
                        <tr>
                            <th scope="col" className="text-center">Symbol</th>
                            <th scope="col" className="text-center">ID</th>
                            <th scope="col" className="text-center">Pair</th>
                            <th scope="col" className="text-center">Amount</th>
                            <th scope="col" className="text-center">Stake amount</th>
                            <th scope="col" className="text-center">Open rate</th>
                            <th scope="col" className="text-center">{closedTable ? "Close rate" : "Current rate"}</th>
                            <th scope="col" className="text-center">{closedTable ? "Profit %" : "Current Profit %"}</th>
                            <th scope="col" className="text-center">Open date</th>
                            {closedTable && <th scope="col" className="text-center">Close date</th>}
                        </tr>
                    </thead>
                    <tbody style={{"height": "30px"}}>
                        {JSON.stringify(table_data_pages) === "{}" ? <tr>{Array.from(Array(10).keys()).map((lv, idx) => {return <td key={idx}></td>})}</tr> :
                         table_data_pages[activePage].map((listValue, index) => {
                            return <tr key={listValue.id}>
                                <td className="text-center"><div className={"cci cci-" + listValue.pair.split(':')[0].split('/')[0]}></div></td>
                                {/* <th scope="row" className="text-center"><i className="fab fa-btc"></i></th> */}
                                {/* <th scope="row" className="text-center"><img src=''/></th> */}
                                <td className="text-center">{listValue.id}</td>
                                <td className="text-center">{listValue.pair.split(':')[0]}</td>
                                <td className="text-center">{listValue.amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                <td className="text-center">{listValue.stake_amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                <td className="text-center">{listValue.open_rate.toLocaleString(undefined, { maximumFractionDigits: 4 })}</td>
                                <td className="text-center">{closedTable ? listValue.close_rate.toLocaleString(undefined, { maximumFractionDigits: 4 }): listValue.current_rate.toLocaleString(undefined, { maximumFractionDigits: 4 })}</td>
                                <td className="text-center">{
                                    closedTable
                                    ?
                                    <strong className={listValue.close_profit_abs > 0 ? 'text-success' : 'text-warning'}>
                                        {listValue.close_profit_abs > 0 ? '+' : '-'}{(listValue.close_profit * 100).toFixed(1)}% ({listValue.close_profit_abs.toFixed(2)}$)
                                    </strong>
                                    :
                                    <strong className={listValue.open_profit_abs > 0 ? 'text-success' : 'text-warning'}>
                                    {listValue.open_profit_abs > 0 ? '+' : '-'}{(listValue.open_profit * 100).toFixed(1)}% ({listValue.open_profit_abs.toFixed(2)}$)
                                    </strong>
                                }</td>
                                <td className="text-center">{listValue.open_date.split('.')[0].replace(':', ' ')}</td>
                                {closedTable && <td className="text-center">{listValue.close_date.split('.')[0].replace(':', ' ')}</td>}
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
                <nav className="my-4">
                {JSON.stringify(table_data_pages) === "{}"? "" :
                    <ul className="pagination justify-content-center">
                        <li className={activePage === 0?"page-item disabled":"page-item"}>
                            <a className="page-link" tabIndex="-1" onClick={() => setActivePage(activePage - 1)}>Previous</a>
                        </li>
                        {Object.keys(table_data_pages).map((listValue, index) => { return renderPagitionLI(index, listValue) })}
                        <li className={activePage === Number(lastPageIndex)?"page-item disabled":"page-item"}>
                            <a className="page-link" onClick={() => setActivePage(activePage + 1)}>Next</a>
                        </li>
                    </ul>
                    }
                </nav>
        </>
    )
}


export function slice_table_data_into_pages(table_data, trades_per_page) {
    const table_data_pages = {}

    for (let i = 0; i < table_data.length; i += trades_per_page) {
        const pageObjects = table_data.slice(i, i + trades_per_page);
        table_data_pages[Math.floor(i / trades_per_page)] = pageObjects;
    }

    return table_data_pages;
}