import React, {useCallback} from 'react'
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import particlesConfig from '../element/ParticlesConfig';
import Bottom from '../element/bottom';
import Footer1 from '../layout/footer1';

export default function Error404() {

    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    return (
        <>
            <Particles id="particles" init={particlesInit} options={particlesConfig} />
            <div className="intro my-4" style={{"height": "500px"}}>
                <div className="container text-center">
                    <h1 className='my-4'>404: Page Not Found</h1>
                
                </div>
            </div>
            <Bottom />
            <Footer1 />
        </>)
}