import React from 'react'
import { slice_table_data_into_pages, PaginatedTable } from './paginated-table-utils'

export default function OpenTradesTable({ table_data }) {
    let table_data_pages = {};
    if (JSON.stringify(table_data) !== "[]") {
        table_data = table_data.sort((a, b) => new Date(a.open_date) - new Date(b.open_date)).reverse()
        table_data_pages = slice_table_data_into_pages(table_data, 10)
    } else {

    }


    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <h5>Open Positions</h5>
                    </div>
                <div className="card-body">
                    <PaginatedTable table_data_pages={table_data_pages} closedTable={false}/>
                </div>
            </div>
        </div>
    )
}
