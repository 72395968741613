import React, { useCallback } from 'react'
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import particlesConfig from '../element/ParticlesConfig';
import Bottom from '../element/bottom';
import Footer1 from '../layout/footer1';

// Images
import { LazyLoadImage } from "react-lazy-load-image-component";
import IMGAbousUs from '../../images/about/about-neuraltraders-cryptocurrency-trading-bot.png'
import IMGFutureVision from '../../images/about/future-vision-of-cryptocurrency.png'
import IMGWhoWeAre from '../../images/who-we-are-neuraltraders.png'

export default function AbousUs() {

    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    return (
        <>
            <Particles id="particles" init={particlesInit} options={particlesConfig} />
            <div className='mt-5'>
                <div className="container">
                    <div className='row justify-content-between align-items-center'>
                        <div className="col-xl-5 col-lg-5 col-12 px-4">
                            <div className="intro-content">
                                <h1>About <strong className="text-primary">NeuralTraders</strong>
                                </h1>
                                <p className='fs-4 pt-3'>Welcome to <strong className='text-primary'>NeuralTraders</strong>, where innovation meets profitability in the world of cryptocurrency trading. We are a team of passionate and experienced professionals dedicated to revolutionising the way you approach digital asset investment.</p>
                                <p className='fs-4'>Unlock the potential of cryptocurrency trading with our automated bot subscription service. From advanced strategies to user-friendly interfaces, we provide a seamless and efficient trading experience tailored to your needs.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12">
                            <LazyLoadImage src={IMGAbousUs} className='w-100' alt='NeuralTraders is a algorithmic cryptocurrency trading bot'/>
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className="container">
                    <h2 className='text-primary my-4'>Mission and Values</h2>
                    <p className='text-secondary fs-4 px-2'>At <strong className='text-primary'>NeuralTraders</strong>, our mission is clear: empower individuals to navigate the complex and dynamic landscape of crypto trading with confidence. We believe that everyone should have access to advanced trading tools, and we're here to make that a reality.</p>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-12 col-md-4 my-4'>
                            <div className='row my-auto'>
                                <div className='col-4 text-center align-self-center'><i className='la la-shield' style={{ "fontSize": "4rem", "color": "white" }}></i></div>
                                <div className='col-7 align-self-center fs-4 mx-2 text-white'>Security and Trustworthiness</div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4 my-4'>
                            <div className='row my-auto'>
                                <div className='col-4 text-center align-self-center'><i className='la la-credit-card pt-3' style={{ "fontSize": "4rem", "color": "white" }}></i></div>
                                <div className='col-7 align-self-center fs-4 mx-2 text-white'>No Extra Payments</div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4 my-4'>
                            <div className='row my-auto'>
                                <div className='col-4 text-center align-self-center'><i className='la la-area-chart pt-3' style={{ "fontSize": "4rem", "color": "white" }}></i></div>
                                <div className='col-7 align-self-center fs-4 mx-2 text-white'>Steady Balance Growth</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-5">
                <div className="container">
                    <h2 className='text-primary my-4'>Company Culture</h2>
                    <p className='text-secondary fs-4 px-2'>At <strong className='text-primary'>NeuralTraders</strong>, our culture is the heartbeat of our organization, shaping the way we work and innovate. We foster a collaborative and inclusive environment where every team member's unique talents are celebrated. Embracing diversity, we believe in the power of varied perspectives to drive creativity and innovation.</p>
                    <div className='row mx-4 justify-content-between'>
                        <div className='col-12 col-sm-4 my-4'>
                            <p className='fs-4 text-secondary'><strong className='text-primary'>Team Collaboration:</strong> We thrive on collaboration, working together to overcome challenges and achieve common goals. Our open communication channels encourage the free exchange of ideas, ensuring that every voice is heard and valued.</p>
                        </div>
                        <div className='col-12 col-sm-4 my-4'>
                            <p className='fs-4 text-secondary'><strong className='text-primary'>Innovation at the Core:</strong> We cultivate an atmosphere where innovation is not just encouraged but celebrated. Our commitment to staying on the cutting edge of industry trends is reflected in our passion for exploring new ideas and pushing boundaries.</p>
                        </div>
                        <div className='col-12 col-sm-4 my-4'>
                            <p className='fs-4 text-secondary'><strong className='text-primary'>Continuous Learning:</strong> Learning is a journey, not a destination. We foster a culture of continuous learning, providing opportunities for skill development, training, and professional growth. Our commitment to knowledge-sharing ensures that we evolve together.</p>
                        </div>
                    </div>
                    <p className='fs-4 text-secondary px-2'>At <strong className='text-primary'>NeuralTraders</strong>, our culture is more than a set of values; it's a way of life. Join us on this exciting journey where every individual is empowered to contribute, innovate, and grow.</p>
                </div>
            </div>

            <div className="">
                <div className="container">
                    <div className='row justify-content-between align-items-center'>
                        <div className="col-xl-6 col-lg-6 col-12">
                            <LazyLoadImage src={IMGWhoWeAre} className='w-100' alt='NeuralTraders was build by tradng experts' />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-4">
                            <div className="intro-content">
                                <h2 className='text-primary'>Who We Are</h2>
                                <p className='text-secondary my-4 fs-4'>Behind every successful algorithmic trading strategy is a team of experts, and we take pride in being at the forefront of this industry. Our team comprises seasoned traders, blockchain enthusiasts, and software developers, all united by a shared vision of democratising access to cutting-edge trading technologies.</p>
                                <br />
                                <h2 className='text-primary'>How It All Began</h2>
                                <p className='text-secondary my-4 fs-4'><strong className="text-primary">NeuralTraders</strong> was born out of a shared passion for cryptocurrencies and a deep understanding of the challenges faced by traders. Frustrated by the lack of accessible and reliable trading tools, we set out to develop a solution that would level the playing field for everyone interested in crypto trading.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                <div className="container">
                <h2 className='text-primary mt-4'>Join Us on the Journey</h2>
                <p className='text-secondary my-4 fs-4'>Whether you're a seasoned trader or just starting your crypto journey, NeuralTraders is here to support you. Explore the power of algorithmic trading with confidence, knowing that you have a dedicated team of experts behind you.</p>
                    <div className='row justify-content-between align-items-center'>
                        <div className="col-xl-6 col-lg-6 col-12 px-4">
                            <div className="intro-content">
                                <h2 className='text-primary'>Future Vision</h2>
                                <p className='text-secondary my-4 fs-4'>At <strong className='text-primary'>NeuralTraders</strong>, we envision simplifying cryptocurrency trading, making it globally accessible. Join us in pioneering a future where innovation meets universal investment confidence.</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-12">
                            <LazyLoadImage src={IMGFutureVision} className='w-100' alt='The future of NeuralTraders is to simplify trading for everyone' />
                        </div>
                    </div>
                </div>
            </div>

            <Bottom />
            <Footer1 />
        </>
    )
}
