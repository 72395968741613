import React, { useRef, useState, useEffect, useCallback } from "react"
import { Form, Button, Card, Alert, Container, Row, Col } from "react-bootstrap"
import { useAuth } from "../auth/auth-context"
import { Link, useNavigate } from "react-router-dom"
import { createUserDocument } from '../auth/firebase'
import { asyncTimeout } from "../../helper_functions"
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import particlesConfig from '../element/ParticlesConfig';
import axios from "axios";


export default function Signup() {
  const firstnameRef = useRef()
  const lastnameRef = useRef()
  const emailRef = useRef()
  const dobRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const approveTermsRef = useRef()
  const isUSsitizenRef = useRef()
  const { signup, currentUser, currentUserData } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  // const location = useLocation()

  useEffect(() => {
    const checkThatPGisCreated = async () => {
      let pgIsCreated = false;
      for (let i = 0; i < 10; i++) {
        await asyncTimeout(500);
        try {
          await axios.get("/api/postgrep", { params: { uid: currentUser.uid } })
          pgIsCreated = true;
          break;
        } catch {
          continue;
        }
      }
      return pgIsCreated;
    }

    const redirect = async () => {
      const pgIsCreated = await checkThatPGisCreated();
      if (pgIsCreated) await navigate('/dashboard/home')
      else await navigate('/')
    }
    if (JSON.stringify(currentUserData) !== '{}') {
      redirect()
    }
  }, [currentUserData])

  function calculate_age(dob) {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  async function handleSubmit(e) {
    // Don't erase entered values in case submit fails
    e.preventDefault()

    // Check that age is above 18
    if (dobRef.current.value === '') {
      return setError("Date of Birth cannot be empty");
    }
    const dob = new Date(Date.parse(dobRef.current.value))
    const age = calculate_age(dob)
    if (age < 18) {
      return setError("You must be over 18 years old");
    }

    // Check that first name is shorter than 20 characters
    if (firstnameRef.current.value.length > 20) {
      return setError("First name must be shorter than 20 characters");
    }

    // Check that first name is shorter than 20 characters
    if (lastnameRef.current.value.length > 20) {
      return setError("Last name must be shorter than 20 characters");
    }

    // Check that password is at least 8 characters
    if (passwordRef.current.value.length < 8) {
      return setError("Password must be at least 8 characters");
    }

    // Check that the passwords are matching
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    if (passwordRef.current.value && emailRef.current.value && firstnameRef.current.value && lastnameRef.current.value) {
      try {
        setError("")
        setLoading(true)
        const { user } = await signup(emailRef.current.value, passwordRef.current.value)
        await createUserDocument({
          email: emailRef.current.value,
          firstName: firstnameRef.current.value,
          lastName: lastnameRef.current.value,
          isUSsitizen: isUSsitizenRef.current.value === 'on' ? true : false,
          uid: user.uid,
          dateOfBirthTS: dob.getTime()
        })
        await asyncTimeout(2000);
        // navigate('/');
      } catch (e) {
        setError("Failed to create an account")
      }
      setLoading(false)
    } else {
      setError("Failed to create an account")
    }
  }

  const particlesInit = useCallback(async engine => {
    await loadSlim(engine);
  }, []); 

  if (!currentUser) {
    return (
      <>
        <Particles id="particles" init={particlesInit} options={particlesConfig} />
        <Container>
          <div className="row h-100 mt-5">
            <Card>
              <Card.Body>
                <h2 className="text-center mb-4">Sign Up</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <strong className="text-danger">*</strong> - required fields
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <Form.Group id="firstname" className="my-2">
                        <Form.Label column="lg" className="mx-2">First Name</Form.Label><strong className="text-danger">*</strong>
                        <Form.Control type="text" ref={firstnameRef} placeholder="write your first name" required />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group id="lastname" className="my-2">
                        <Form.Label column="lg" className="mx-2">Last Name</Form.Label><strong className="text-danger">*</strong>
                        <Form.Control type="text" ref={lastnameRef} placeholder="write your last name" required />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <Form.Group id="email" className="my-2">
                        <Form.Label column="lg" className="mx-2">Email</Form.Label><strong className="text-danger">*</strong>
                        <Form.Control type="email" ref={emailRef} placeholder="write your email" required />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group id="dateOfBirth" className="my-2">
                        <Form.Label column="lg" className="mx-2">Date of Birth</Form.Label><strong className="text-danger">*</strong>
                        <Form.Control type="date" ref={dobRef} required />
                      </Form.Group>
                    </div>
                  </div>
                  <Form.Group id="password" className="my-2">
                    <Form.Label column="lg" className="mx-2">Password</Form.Label><strong className="text-danger">*</strong>
                    <Form.Control type="password" ref={passwordRef} placeholder="at least 8 characters" required />
                  </Form.Group>
                  <Form.Group id="password-confirm" className="my-2">
                    <Form.Label column="lg" className="mx-2">Password Confirmation</Form.Label><strong className="text-danger">*</strong>
                    <Form.Control type="password" ref={passwordConfirmRef} placeholder="at least 8 characters" required />
                  </Form.Group>
                  <Row className="my-4 mx-4" id="formGridCheckbox" as={Row}>
                    <Form.Check type="checkbox" className="my-2">
                      <Form.Check.Input className="my-2" type="checkbox" ref={approveTermsRef} required />
                      <Form.Check.Label><strong className="text-danger text-nowrap mx-0 my-0 py-0 px-0">*</strong> By clicking this checkbox, you acknowledge your agreement to abide by our <Link to={"/terms-of-use"}>Terms of Use</Link>, which outline the rules and responsibilities governing your use of our platform. Be sure to review these terms thoroughly before proceeding.</Form.Check.Label>
                    </Form.Check>
                    <Form.Check type="checkbox" className="my-2">
                      <Form.Check.Input className="my-2" type="checkbox" ref={isUSsitizenRef} />
                      <Form.Check.Label>Are you a citizen or resident of the United States? If you are, please mark this checkbox.</Form.Check.Label>
                    </Form.Check>
                  </Row>
                  <br />
                  <Row>
                    <Button disabled={loading} size="lg" className="w-50 mx-auto" type="submit">
                      Sign Up
                    </Button>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
            <div className="w-100 text-center mb-2 w-0">
              Already have an account? <Link to="/login">Log In</Link>
            </div>
          </div>
        </Container>
      </>
    )
  } else {
    <div className="intro"></div>
  }
}