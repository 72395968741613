import React, { useCallback } from 'react'
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import particlesConfig from '../element/ParticlesConfig';
import PricingTable from '../element/pricing-table';
import Bottom from '../element/bottom';
import Footer1 from '../layout/footer1';


export default function PricingPage() {
    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    // const pricing_data = {
    //     'basic': {'title': 'Basic', 'price_monthly': '29', 'price_annually': '280'},
    //     'pro': {'title': 'Pro', 'price_monthly': '49', 'price_annually': '470'}
    // }

    const pricing_description = [
        {
            "name": "Supported Exchange",
            "values": ["Binance", "Binance", "Binance"]
        },
        {
            "name": "Account type",
            "values": ["Demo", "Real", "Real"]
        },
        {
            "name": "Max. Number of Coins",
            "values": ["20", "20*", "100*"]
        },
        {
            "name": "Max. Number of Bots",
            "values": ["1", "1", "3"]
        },
        {
            "name": "Available bots",
            "values": ["1", "1", "5"]
        },
        {
            "name": "Customer Service",
            "values": [<i className="las la-check"></i>, <i className="las la-check"></i>, <i className="las la-check"></i>]
        },
        {
            "name": "Grid Bot",
            "values": [<i className="las la-times"></i>, <i className="las la-times"></i>, <i className="las la-check"></i>]
        },
        {
            "name": "AI-suggested Improvements",
            "values": [<i className="las la-times"></i>, <i className="las la-times"></i>, <i className="las la-check"></i>]
        },
        {
            "name": "Trading Newsletter",
            "values": [<i className="las la-times"></i>, <i className="las la-times"></i>, <i className="las la-check"></i>]
        },
        
    ]

    return (
        <>
            <Particles id="particles" init={particlesInit} options={particlesConfig} />
            <PricingTable />
            {/* <div style={{"height": "50px"}} /> */}
            <div className="container my-4 text-center">
                <h2 className='pb-5'>Table of Comparison</h2>
                <div className='row h-100 pb-5'>
                    <table className="table table-striped table-dark fs-4 text-center table-bordered table-hover">
                        <thead style={{'height': "70px"}}>
                            <tr>
                                <th scope="col" className='text-primary'>Feature</th>
                                <th scope="col" className='text-primary'>Free Trial</th>
                                <th scope="col" className='text-primary'>Basic</th>
                                <th scope="col" className='text-primary text-center'>Pro</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pricing_description.map((listValue, index) => {
                            return <tr key={index}>
                                <td >{listValue.name}</td>
                                <td className='text-secondary'>{listValue.values[0]}</td>
                                <td className='text-secondary'>{listValue.values[1]}</td>
                                <td className='text-secondary text-center'>{listValue.values[2]}</td>
                            </tr>})}
                            {/* <tr>
                                <td>Customer Service</td>
                                <td><i className="las la-check"></i></td>
                                <td><i className="las la-times"></i></td>
                                <td className='text-center'>@mdo</td>
                            </tr> */}
                        </tbody>
                    </table>
                    <p className='fs-4 text-secondary my-4'>* The amount of Cryptocurrencies will depend on your account's trading balance as the Minimal Order Amount on Binance is 5$ per trade. For the proper work of the NeuralTraders bot your Binance trading account's balance should be above 255$.</p>
                </div>

                {/* <div style={{"height": "100px"}} /> */}
            </div>
            <Bottom />
            <Footer1 />
        </>
    )
}
