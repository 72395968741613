import React, {useCallback} from 'react'
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import particlesConfig from '../element/ParticlesConfig';
import Bottom from '../element/bottom';
import Footer1 from '../layout/footer1';

// Images
import IMGTradingSteadyGrowth from '../../images/trading-steady-growth-and-analysis.png'
import IMGTradingBots from '../../images/cryptocurrency-algorithmic-trading-bot.png'
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function TradingBots() {

    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    return (
        <>
            <Particles id="particles" init={particlesInit} options={particlesConfig} />
            <div className='mt-5'>
                <div className="container">
                    <div className='row justify-content-between align-items-center'>
                        <div className="col-xl-5 col-lg-5 col-12 px-4">
                            <div className="intro-content">
                                <h1>How It Works: <strong className="text-primary">Pivot Points Strategy</strong>
                                </h1>
                                <p className='fs-4 pt-3'>Welcome to NeuralTraders, where precision meets profitability through our advanced Pivot Points trading strategy. Our algorithmic trading bot is meticulously crafted to harness the power of Pivot Points, providing you with a strategic advantage in the dynamic world of cryptocurrency trading. Let's explore how our bot operates step by step.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12">
                            <LazyLoadImage src={IMGTradingBots} className='w-100' alt='Image demonstrating Trading Bot logic' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-2">
                <div className="container">
                    <h2 className='text-primary mt-4'>1. Pivot Points Analysis</h2>
                    <p className='text-secondary my-4 fs-4'>At the core of our trading bot is the Pivot Points strategy. The algorithm meticulously analyses historical price data to identify key Pivot Points, which serve as critical levels for potential price reversals or continuation. These points are dynamically adjusted based on market conditions, ensuring adaptability to the ever-changing crypto landscape.</p>
                </div>
            </div>

            <div className="py-2">
                <div className="container">
                    <h2 className='text-primary mt-4'>2. Automatic Execution</h2>
                    <p className='text-secondary my-4 fs-4'>Our algorithm automatically executes trades based on the identified Pivot Points, removing the need for manual intervention. This not only enhances efficiency but also ensures that algorytm’s trading decisions are grounded in a data-driven and systematic approach.</p>
                </div>
            </div>
            
            <div className="py-2">
                <div className="container">
                    <h2 className='text-primary mt-4'>3. Risk Management</h2>
                    <p className='text-secondary my-4 fs-4'>Protecting your investment is paramount. Our trading bot prioritises risk mitigation, that is why we only use the spot market avoiding any risks such as leverage and volatility.</p>
                </div>
            </div>

            <div className="py-2">
                <div className="container">
                    <h2 className='text-primary mt-4'>4. Real-Time Monitoring</h2>
                    <p className='text-secondary my-4 fs-4'>Stay informed about your portfolio's performance with our real-time monitoring tools. Track executed trades, view performance analytics, and receive timely notifications. Our intuitive dashboard provides a comprehensive overview of your trading activities.</p>
                </div>
            </div>

            <div className="py-2">
                <div className="container">
                    <h2 className='text-primary mt-4'>5. Continuous Optimization</h2>
                    <p className='text-secondary my-4 fs-4'>The cryptocurrency market is dynamic, and so is our commitment to optimization. Our development team continually refines the strategy based on market trends and user feedback. This ensures that you always have access to a sophisticated and adaptive trading tool.</p>
                </div>
            </div>

            <div className='mb-5'>
                <div className="container">
                    <div className='row justify-content-between align-items-center'>
                        <div className="col-xl-5 col-lg-5 col-12">
                            <LazyLoadImage src={IMGTradingSteadyGrowth} className='w-100' alt='NeuralTraders steady growth with algorithmic cryptocurrency trading.' />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-4">
                            <div className="intro-content">
                                <h1>Ready to <strong className="text-primary">Pivot?</strong>
                                </h1>
                                <p className='fs-4 pt-3'>Experience the precision of automated trading with our enhanced strategy. Join NeuralTraders today and elevate your crypto trading experience. Let our bot navigate the complexities of the market, allowing you to capitalise on opportunities with confidence.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom />
            <Footer1 />
        </>)
}