import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { month_map } from "../../helper_functions";

function DailyProfit({ botPerformance }) {

  const computeDailyProfit = () => {
    let dailyTrades = {}
    for (let ct of botPerformance.closed_trades) {
      let date = ct.close_date.split('T')[0]
      if (!(date in dailyTrades)) {
        dailyTrades[date] = 0
      }
      dailyTrades[date] += 1
    }
    let _series = []
    for (let dt in dailyTrades) {
      let dt_date = new Date(dt)
      _series.push({ 'x': dt_date, 'y': dailyTrades[dt] })
    }
    _series.sort((a, b) => a.x - b.x);
    for (let entry of _series) {
      entry.x = `${month_map[entry.x.getMonth()]} ${entry.x.getDate()}`
    }
    return _series
  }

  const getSeries = () => {
    if (JSON.stringify(botPerformance) === '{}') {
      return [];
    } else {
      return [{
        name: 'Daily trades',
        data: computeDailyProfit()
      }]
    }
  }

  const chart_options = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        tickAmount: 4,
        labels: {
          rotate: 0,
          style: {
            colors: '#d4d4d4', // Customize the color of the x-axis labels
          },
        },
        reversed: false,
      },
      yaxis: {
        labels: {
          style: {
            colors: '#d4d4d4', // Customize the color of the y-axis labels
          },
        },
      },
      grid: {
        borderColor: '#484e5c',
      },
      colors: ['#5d78ff'],
      dataLabels: {
        enabled: false
      },
      noData: {
        text: "Data loading...",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "white",
          fontSize: '14px',
          fontFamily: undefined
        }
      },
    },
    series: getSeries()
  }



  return (
    <>
      <Chart
        options={chart_options.options}
        series={chart_options.series}
        type="bar"
      />
    </>
  );
}

export default DailyProfit;