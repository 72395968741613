import React, { useCallback } from 'react'
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import particlesConfig from '../element/ParticlesConfig';
import Bottom from '../element/bottom';
import Footer1 from '../layout/footer1';

// Images
import IMGAPIComplete from '../../images/setup-guide/binance-api-complete.png'
import IMGApiIAgree from '../../images/setup-guide/binance-api-i-agree.png'
import IMGChooseApiType from '../../images/setup-guide/binance-choose-api-type.png'
import IMGCreateApi from '../../images/setup-guide/binance-create-api.png'
import IMGCreateApiButton from '../../images/setup-guide/binance-create-api-button.png'
import IMGEditApi from '../../images/setup-guide/binance-edit-api.png'
import IMGSecurityVerification from '../../images/setup-guide/binance-security-verification.png'
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function SetupGuide() {

    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    return (
        <>
            <Particles id="particles" init={particlesInit} options={particlesConfig} />
            <div className='mt-5'>
                <div className="container py-4">

                    <div className="intro-content">
                        <h1 className='py-4'>Here's How You Connect <strong className="text-primary">NeuralTraders</strong> to Your <strong className="text-primary">Binance Account</strong></h1>
                        <p className='fs-4 pt-3'>Follow these easy steps to connect your Binance account to NeuralTraders:</p>
                    </div>
                </div>
            </div>

            <div className="">
                <div className="container">
                    <h4 className='text-white mt-4'>1. Go to <a href="https://www.binance.com">Binance</a> and log in or create a new account.</h4>
                    <h4 className='text-white mt-4'>2. Click the profile button in the top right corner of the Binance exchange interface and click on "API Management".</h4>
                    <h4 className='text-white mt-4'>3. Click "I agree" on the pop-up "Tips" block.</h4>
                    <div className='row justify-content-center py-3'>
                        <LazyLoadImage src={IMGApiIAgree} style={{width: "400px"}} alt='Click Agree in Binance API Setup'/>
                    </div>
                    <h4 className='text-white mt-4'>4. Click "Create API".</h4>
                    <div className='row justify-content-center py-3'>
                        <LazyLoadImage src={IMGCreateApiButton} style={{width: "500px"}} alt='Click create API in Binance API Setup' />
                    </div>
                    <h4 className='text-white mt-4'>5. "Choose API Key type" has to be "System Generated".</h4>
                    <div className='row justify-content-center py-3'>
                        <LazyLoadImage src={IMGChooseApiType} style={{width: "500px"}} alt='Choose API key type in Binance API Setup'/>
                    </div>
                    <h4 className='text-white mt-4'>6. Create an API name.</h4>
                    <div className='row justify-content-center py-3'>
                        <LazyLoadImage src={IMGCreateApi} style={{width: "500px"}} alt='Create API name in Binance API Setup' />
                    </div>
                    <h4 className='text-white mt-4'>7. Enter your 2FA codes.</h4>
                    <div className='row justify-content-center py-3'>
                        <LazyLoadImage src={IMGSecurityVerification} style={{width: "500px"}} alt='Enter 2FA in Binance API Setup' />
                    </div>
                    <h4 className='text-white mt-4'>8. Click "Edit" on your new API Key.</h4>
                    <div className='row justify-content-center py-3'>
                        <LazyLoadImage src={IMGEditApi} style={{width: "600px"}} alt='Click Edit on your new API in Binance API Setup' />
                    </div>
                    <h4 className='text-white mt-4'>9. Click on "Enable Spot & Margin Trading" (make sure that the "Enable Withdrawals" stays unclicked as we don't need any access to your funds).</h4>
                    <div className='row justify-content-center py-3'>
                        <LazyLoadImage src={IMGAPIComplete} style={{width: "700px"}} alt='Enable Spot and Margin Trading in Binance API Setup' />
                    </div>
                    <h4 className='text-white mt-4'>10. Save the changes for API Key.</h4>
                    <h4 className='text-white mt-4'>11. Copy the "API Key" and "Secret Key" and paste these two parameters to NeuralTraders form to connect your Binance API to NeuralTraders.</h4>
                    <h4 className='text-white mt-4'>12. Make sure your Binance trading account's balance is above 10$ or otherwise verification will not be successful. Click "Submit".</h4>
                    <h4 className='text-white my-4'>13. That's it! You are ready to start NeuralTraders bot.</h4>
                    <div className='my-4' style={{"height": "100px"}}></div>
                    {/* <p className='text-secondary my-4 fs-4'>At the core of our trading bot is the Pivot Points strategy. The algorithm meticulously analyses historical price data to identify key Pivot Points, which serve as critical levels for potential price reversals or continuation. These points are dynamically adjusted based on market conditions, ensuring adaptability to the ever-changing crypto landscape.</p> */}
                </div>
            </div>

            <Bottom />
            <Footer1 />
        </>)
}