import React, {useState, useEffect} from 'react'
import { useAuth } from '../auth/auth-context'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { setKeyOfUserDocument } from '../auth/firebase'

export default function PricingTable() {
    const [subMode, setSubMode] = useState(true)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const { currentUser } = useAuth()
    const navigate = useNavigate()

    const checkout = async (plan_type) => {
        if (currentUser) {
            setLoading(true)
            setKeyOfUserDocument(currentUser.uid, {'membership': {'sessionId': null}})
            await axios.get("/api/create-subscription-checkout", {params: { planType: plan_type, isAnually: subMode, uid: currentUser.uid }})
                .then((res) => {
                    // console.log('res', res)
                    // console.log(res.statusText);
                    if (res.statusText === 'OK') return res.data;
                    // console.log(res.statusText);
                    return Promise.reject(res.data);
                })
                .then(({ session }) => {
                    // console.log("Session URL", session)
                    window.location = session.url;
                })
                .catch((e) => setError("Purchases are temporarily disabled. Try again later..."))
                // .catch((e) => console.log(e))
            setLoading(false)
        } else {
            navigate("/signup")
        }
      };

    useEffect(() => {
        if (currentUser) {
            setKeyOfUserDocument(currentUser.uid, {'membership': {'sessionId': null}})
        }
    }, [])

    return (
        <>
            <div className="pricing-table section-padding">
                <div className="container">
                    <hr></hr>
                    <h2 className='text-white my-5 text-center'>Pricing Table</h2>
                    <div className="form-check form-switch justify-content-center d-flex align-items-center my-4 px-0">
                        <label className="form-check-label fs-3" htmlFor="flexSwitchCheckChecked">monthly</label>
                        <input className="form-check-input fs-3 mx-3" type="checkbox" role="switch" id="flexSwitchCheckChecked" disabled={loading} onClick={() => setSubMode(!subMode)} />
                        <label className="form-check-label fs-3" htmlFor="flexSwitchCheckChecked">annually</label>
                    </div>
                    {error && <div className='row fs-4 text-white bg-primary'><div className="col text-center my-2">{error}</div></div>}
                    <div className="row justify-content-center d-flex align-items-center mt-4">
                        <div className="col-lg-4 text-center h-100">
                            <div className="card border border-secondary text-center">
                                <div className='card-header border-primary justify-content-center  mt-1'><h3 className='text-primary'>Free Demo Account</h3></div>
                                <div className='card-body justify-content-center'>
                                    <h2 className='my-4'>0$ <strong className='text-secondary'>forever</strong></h2>
                                    <br />
                                    <p className='h4 text-secondary my-3'>Demo account</p>
                                    <p className='h4 text-secondary my-3'>up to 20 coins</p>
                                    <p className='h4 text-secondary my-3'>1 exchange API bot</p>
                                    <p className='h4 text-secondary my-3'>1 trading strategy</p>
                                    <p className='h4 text-secondary my-3'>24/7 Support</p>
                                    <div className="d-grid gap-2">
                                        <button
                                            onClick={currentUser ? () => navigate("/dashboard/home") : () => navigate("/signup")}
                                            className="btn btn-outline-light success btn-lg my-4"
                                            disabled={loading}
                                        >
                                            {currentUser ? 'DASHBOARD' : 'START FREE'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 text-center h-100">
                            <div className="card border border-primary text-center py-3 mx--1">
                                <div className='card-header border-primary justify-content-center mt-1'><h3 className='text-primary'>Basic</h3></div>
                                <div className='card-body justify-content-center'>
                                    <h2 className='my-4'>
                                        <strong>${subMode ? "29" : "23"}</strong> <strong className='text-warning' style={{"textDecoration": "line-through"}}>{subMode ? '$35' : ''}</strong> <strong>/</strong>
                                        <strong className='text-secondary'>{subMode ? " month" : " month"}</strong> <strong className='text-success fs-3'>{subMode ? "" : "( -35% )"}</strong>
                                    </h2>
                                    {subMode ? <p className='h4 text-warning my-3'>Christmas Discount (-20%)</p> : <br />}
                                    <p className='h4 text-secondary my-3'>Real account</p>
                                    <p className='h4 text-secondary my-3'>up to 20 coins</p>
                                    <p className='h4 text-secondary my-3'>1 exchange API bot</p>
                                    <p className='h4 text-secondary my-3'>1 trading strategy</p>
                                    <p className='h4 text-secondary my-3'>24/7 Support</p>
                                    <div className="d-grid gap-2">
                                        <button className="btn btn-primary btn-lg my-4" onClick={() => checkout('basic')} disabled={loading}>BUY NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 text-center h-100">
                            <div className="card border border-secondary text-center">
                                <div className='card-header border-primary justify-content-center  mt-1'><h3 className='text-primary'>Pro</h3></div>
                                <div className='card-body justify-content-center'>
                                    <h2 className='my-4'>
                                        <strong>${subMode ? "49" : "39"}</strong> <strong className='text-warning' style={{"textDecoration": "line-through"}}>{subMode ? '$59' : ''}</strong> <strong>/</strong>
                                        <strong className='text-secondary'>{subMode ? " month" : " month"}</strong> <strong className='text-success fs-3'>{subMode ? "" : "( -30% )"}</strong>
                                    </h2>
                                    {subMode ? <p className='h4 text-warning my-3'>Christmas Discount (-20%)</p> : <br />}
                                    <p className='h4 text-secondary my-3'>Real account</p>
                                    <p className='h4 text-secondary my-3'>up to 50 coins</p>
                                    <p className='h4 text-secondary my-3'>1 exchange API bot</p>
                                    <p className='h4 text-secondary my-3'>3 trading strategy</p>
                                    <p className='h4 text-secondary my-3'>24/7 Support</p>
                                    <div className="d-grid gap-2">
                                        <button className="btn btn-primary btn-lg my-4" onClick={() => checkout('pro')} disabled={loading}>BUY NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
